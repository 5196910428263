@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  overflow-y: scroll;
}

/* Reset some Bootstrap defaults */
body, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: normal;
    font-family: 'Inter', sans-serif;
  }

  h1 {
    color: #101828;
    font-family: 'Inter', sans-serif; /* Upewnij się, że czcionka Inter jest zaimportowana */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
  }  

h2 {
    color: #101828;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
  }

  h3 {
    color: #344054;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
  }
  
  p {
    font-size: 0.875rem;
  }

a {
  text-decoration: none;
}

a, 
button,
.cursor-pointer,
[role="button"],
.clickable {
  cursor: pointer !important;
}

  *,
  *::before,
  *::after {
    box-sizing: border-box; /* Ensure padding and border are within the width and height */
  }

#root {
  min-height: 100vh; /* Kontener zajmuje całe okno */
  display: flex;
  flex-direction: column;
  background-color: #FCFCFD
}

.display-none {
  display: none !important;
}

@media (min-width: 769px) {
  .display-mobile-only {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .display-desktop-only {
    display: none !important;
  }
}

.full-width {
  width: 100% !important;
  text-align: center;
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
.app-header,
.container-fluid {
  overflow: visible !important;
}

}

@media (min-width: 768px) and (max-width: 992px) {
  .app-header-container .col-md-12 {
    width: 95%
  }

  .app-header-container .px-3 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media (max-width: 830px) {
  .homepage-nav-link {
    display: none !important;
  }
}

.nmt-2 {
  margin-top: -30px;
}

.desktop-menu {
  display: none;
  font-size: 13px;
  font-weight: 600;
  margin-right: 10px;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  position: relative;
  overflow: visible;

}


.desktop-menu .user-avatar {
  width: 34px;
  height: 34px;
  margin-left: 2px;
}

.desktop-menu a {
  color: white;
  text-decoration: none;
}

.desktop-menu .signup-desktop {
  flex: 1 0 auto;
  padding: 10px 15px 10px 14px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 155.556% */
}

.desktop-menu .signup-desktop:hover {
  text-decoration: none !important;
}

.desktop-menu .desktop-username {
  margin-right: 24px;
}

.desktop-menu .desktop-profile-button {
  padding-top: 2px;
  padding-bottom: 1px;
  padding-right: 2px;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
}

.desktop-menu .quick-icon {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(0deg) brightness(200%) contrast(100%);

}

.desktop-menu .dropdown-menu .quick-icon {
  filter: none;
  width: 15px;
  height: 15px;
}

.desktop-menu .dropdown-menu .dropdown-item:hover .quick-icon {
  filter: invert(26%) sepia(90%) saturate(1675%) hue-rotate(243deg) brightness(83%) contrast(91%);

}

.explore-container {
  display: inline-block;
}

.nav-profile-container {
  display: inline-block;

}

.explore-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
}

.profile-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  z-index: 9999;
}

.menu-right.show {
  right: 0;
  left: auto;
  text-align: left;
}

.desktop-menu .dropdown-menu {
  min-width: 200px;
  background-color: white;
  border: 1px solid var(--Gray-300, #EAECF0);
  border-radius: 8px;
  box-shadow: 0 4px 10px 0px rgba(16, 24, 40, 0.05);
  padding: 6px;
  display: flex !important;
  gap: 6px !important;
  flex-direction: column;
  margin-top: 6px;
}

.nav-line {
  width: 1px;
  height: 22px;
  background:#F9F5FF;
  opacity: 0.8;
  margin-top:1px;
}

.desktop-menu .dropdown-item {
  display: flex !important;
  gap: 9px !important;
  width: 100%;
  padding: 8px 16px;
  justify-content: left !important;
    align-items: left !important;
  background: none;
  border: none !important;
  color: #212529;
  font-size: 13px !important;
  padding: 10px 14px 10px 14px !important;
  border-radius: 5px !important;
  width: 100% !important;
}

.best-played,
.this-season,
.weekly-section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.best-played .dropdown-menu {
  min-width: 200px;
  background-color: white;
  border: 1px solid var(--Gray-300, #EAECF0);
  border-radius: 8px;
  box-shadow: 0 4px 10px 0px rgba(16, 24, 40, 0.05);
  padding: 6px;
  gap: 6px !important;
  flex-direction: column;
  margin-top: 2px;
}

.best-played .dropdown-item {
  display: flex !important;
  gap: 9px !important;
  width: 100%;
  justify-content: left !important;
    align-items: left !important;
  background: none;
  border: none !important;
  color: #212529;
  font-size: 15px !important;
  padding: 13px 16px 12px 16px !important;
  border-radius: 5px !important;
  width: 100% !important;
}


.dropdown-item:hover {
  background: #F4EBFF !important;
  text-decoration: none !important;
  color: #6941C6 !important;
}

.btn-logout {
  color: #B42318 !important;
}

.btn-logout:hover {
  background-color: #FEF3F2 !important; /* Darker purple on hover and other states */
  text-decoration: none; /* No text decoration on hover */
  color: #B42318 !important;
}

.btn-logout .quick-icon {
  filter: invert(16%) sepia(69%) saturate(4570%) hue-rotate(355deg) brightness(83%) contrast(89%) !important;

}

.nav-chevron {
  width: 18px;
  height: 18px;
}

.desktop-menu .logout-desktop {
  padding: 0;
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  position: relative;
    color: #FEF3F2;
    text-decoration: none;
}

.desktop-menu a:hover,
.desktop-menu .btn:hover {
  text-decoration: underline;
  color: #fff;
}

.desktop-menu .quick-icon {
  margin-left: -2px;
}

/* HTML: <div class="loader"></div> */

.loader-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top:140px;
  padding-bottom: 140px;
}

.loader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#D0D5DD 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}

@media (min-width: 768px) {
  .container, .container-md, .container-sm {
      max-width: none;
  }

  .desktop-menu {
    display: flex;

  }

  .mobile-menu-icon {
    display: none;
  }

  .mobile-search-icon {
    display: none;
  }

  .resp-regular {
    width: 70% !important;
  }

  .search-results-container {
    display: flex;
    width:100%;
    justify-content: center;
  }

}
@media (min-width: 576px) {
  .container, .container-sm {
      max-width: none;
  }
}

@media (min-width: 1600px) {
  .resp-pre-xl {
    width: 60%; /* lub inna szerokość dostosowana do Twojego interfejsu */
  }
}

@media (min-width: 1900px) {
  .resp-pre-xl {
    width: 1320px; /* lub inna szerokość dostosowana do Twojego interfejsu */
  }
  .px12 {
    padding-left: 12px;
    padding-right: 12px;
  }
}


.page-title {
  margin-top: 1rem !important;
  margin-bottom: 2.2rem !important; /* Twoja nowa wartość */


}
  .mb-5 {
    margin-bottom: 2.2rem !important; /* Twoja nowa wartość */
  }

  .section-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  /* Rozwiązanie 1: Bardziej specyficzny selektor */
  .section-title-wrapper button.btn,
  .section-title-wrapper .btn[variant="tertiary"] {
    width: fit-content;
    min-width: min-content;
    flex: 0 0 auto;
  }
  
  /* Rozwiązanie 2: Z !important (jeśli inne style nadal nadpisują) */
  .section-title-wrapper .btn {
    width: auto !important;
    min-width: min-content !important;
    flex: 0 0 auto !important;

  }

  .section-title-wrapper .btn {
    padding: 10px 16px;
    gap: 12px;
    flex: 1 0 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 155.556% */
    margin: 0;
    box-shadow: none;
    margin-bottom: 12px !important;
    margin-top:1px;
  }

  .section-title {
    color: #101828;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 12px;
  }

  @media (min-width: 769px) and (max-width: 1200px) {
    .section-title {
    padding-bottom: 8px;
    }
  }

  .section-title .title-link {
    display: flex;
    align-items: center;
    gap: 12px; /* odstęp między tekstem a ikoną */
    color: #101828;
    transition: color 0.2s ease; /* płynna zmiana koloru */
  }
  .section-title .title-link:hover {
    color: #7F56D9;
  }

  .section-title .title-link:hover .section-title-arrow {
    color: #7F56D9;
  }
  
  .section-title-arrow {
    color: #101828;
    transition: color 0.2s ease; /* płynna zmiana koloru */
    position: relative;
    top: 0.5px;
    width: 13px;
    height: 17px;
  }


  .section-subtitle {
    padding-bottom: 10px;
  }

  /* Main container styling */
  .main-container {
    font-family: inherit; /* Dziedziczy czcionkę z body */
    padding-left: 24px; /* Bootstrap default padding */
  padding-right: 24px; /* Bootstrap default padding */
  overflow-x: hidden; /* Prevent horizontal overflow */
  background-color: #FCFCFD;
  
  }

  .homepage {
    background-color: #FCFCFD;
    position: relative;

  }

  .s-bg-primary {
    background-color: #101828;    
  }
  
  /* Header with Search */
  /* Style for the app header */
.app-header {
    background-color: #101828;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1.6rem;
  }

  @media (max-width: 500px) {
    .app-header {
      padding: 0.3rem 1.6rem;
    }
  }

  .s-bg-primary.mx-n3, .app-header.mx-n3 {
    margin-right: -16px;
    margin-left: -16px;
  }

  /* Style dla kontenera wewnątrz headera */
.app-header > .container,
.app-header > .container-fluid {
  padding-left: 24px;
  padding-right: 24px;
}
  
  .logo {
    height: 40px; /* Dostosuj rozmiar logo do swoich potrzeb */
    width: auto;
    position:relative;
    top:2px;
  }
  
  .navigation-icons {
    display: flex;
    align-items: center;
    margin-right: -4px;
  }
  
  .icon {
    margin-left: 0.6rem;
    margin-right: 0rem;
    height: 44px; /* Dostosuj rozmiar ikon do swoich potrzeb */
    width: auto;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.08);
    padding: 10px;
    border-radius: 10px;
    transition: background 0.2s ease; /* Dodaj tę linię */
  }

.cursor-pointer {
  cursor: pointer;
}

  .icon:hover {
    background: rgba(255, 255, 255, 0.16);   
  }

.quick-button {
    width: 100%;
  }

  
  @media (min-width: 700px) {
    .quick-button {
      width: auto;
    }

    .showsseen-buttons {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }

    .showpage-buttons .btn {
      width: auto;
      flex:none;
    }
  }
/* Styl dla paska wyszukiwania */
.nav-search-bar {
    background-color: #fff; /* Białe tło dla paska wyszukiwania */
    padding: 0.5rem;
    position: absolute; /* Możesz potrzebować dostosować położenie */
    top: 90px; /* Wysokość nagłówka + padding */
    left: 0;
    right: 0;
    display: flex;
    justify-content: center; /* Wycentruj wyszukiwarkę */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Cień dla efektu głębi */
    z-index: 1000; /* Upewnij się, że wyszukiwarka jest na wierzchu */
  }

  
  /* Styl dla menu */
  .navigation-menu {
    background-color: #fff; /* Białe tło dla menu */
    position: absolute; /* Absolutne pozycjonowanie względem rodzica */
    top: 90px; /* Wysokość nagłówka + padding */
    left: 0;
    width: 100%; /* Pełna szerokość */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Cień dla efektu głębi */
    z-index: 1000; /* Upewnij się, że menu jest na wierzchu */
  }
  
  .navigation-menu a {
    display: block; /* Każdy link jako osobny blok */
    padding: 0.5rem 1rem; /* Padding dla linków */
    color: #7b1fa2; /* Kolor tekstu */
    text-decoration: none; /* Brak podkreślenia */
    border-bottom: 1px solid #eee; /* Subtelna linia oddzielająca */
    font-size: 1rem;
  }
  
  .navigation-menu a:hover {
    background-color: #f9f9f9; /* Lekko różne tło przy najechaniu */
  }
  
/* Style dla sekcji powitania */
.greeting-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.7rem;
  margin-bottom: 1rem;
}

.rating-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rating-top-section-text {
  display: flex; /* Ustaw flexbox dla kontenera */
  flex-direction: column; /* Elementy ułożone pionowo */
  justify-content: center; /* Wycentruj elementy pionowo */
  height: 100%; /* Ustaw pełną wysokość, aby wykorzystać dostępną przestrzeń */
  text-align: left;
}


.greeting-section .header-title {
  color: #101828;
  font-size: 1.5rem;
  font-weight: bold;
}

.greeting-section .header-subtitle {
  color: #475467;
  font-size: 1rem;
  margin: 0;
  padding-top:3px;
}
.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.no-border-radius {
  border-radius: 0% !important;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.greeting-section button {
  color: #667085
}

.greeting-text-container {
  display: flex; /* Ustaw flexbox dla kontenera */
  flex-direction: column; /* Elementy ułożone pionowo */
  justify-content: center; /* Wycentruj elementy pionowo */
  height: 100%; /* Ustaw pełną wysokość, aby wykorzystać dostępną przestrzeń */
  text-align: left;
}

/* Style dla paska wyszukiwania */
.search-section {
  margin-bottom: 1rem;
}


  
/* SECTION - Featured shows */

.slick-slider {
  margin-left: -5px; /* Adjust based on actual padding */
  margin-right: -5px; /* Adjust based on actual padding */

}

.slick-arrow:before {
  content: none !important; /* Usunięcie zawartości */
  display: none !important; /* Ukrycie elementu */
}

.featured-shows .slick-arrow {
  position: absolute;
  top:-32px;
  width: 34px;
  height: 34px;
}

@media (max-width: 768px) {
  .trending-section .slick-arrow,
  .this-season .slick-arrow {
    position: absolute;
    top:-32px;
    width: 34px;
    height: 34px;
  }
}

.tags-featured {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  flex: wrap;
  padding: 8px;
  gap: 4px;
}

.featured-shows .tag {
  display: flex;
  padding: 2px 7px;
  justify-content: center;
  align-items: center;
  background: rgba(249, 245, 255, 0.8);
  color: var(--Primary-700, #6941C6);
  font-size: 10px;
  line-height: 15px; /* 150% */
  margin-top:2px;
}

.featured-shows .tag-secondary,
.featured-shows .tag-tertiary,
.featured-shows .tag-quaternary,
.featured-shows-desktop .desktop-tags .tag-secondary,
.featured-shows-desktop .desktop-tags .tag-tertiary,
.featured-shows-desktop .desktop-tags .tag-quaternary,
.this-season .tag-secondary,
.this-season .tag-tertiary,
.this-season .tag-quaternary {
  display: none !important;
}

.featured-shows-desktop .desktop-tags {
  display: flex;
  flex: wrap;
  gap: 4px;
  margin-bottom: 3px;
}

.featured-shows-desktop .desktop-tags .tag,
.trending-section .desktop-tags .tag {
  display: flex;
  padding: 2px 7px;
  justify-content: center;
  align-items: center;
  background-color: rgba(16, 24, 40, 0.8) !important;
  color: #D0D5DD !important;
  font-size: 10px;
  line-height: 15px; /* 150% */
  margin-top:1px;
}

  

.featured-shows .slick-prev {
  right: 39px;
  left:auto;
}

@media (max-width: 786px) {
.trending-section .slick-prev,
.this-season .slick-prev {
  right: 39px;
  left:auto;
}
.trending-section .slick-next,
.this-season .slick-next {
  right:0px;
}
.trending-section .slider-custom-icon,
.this-season .slick-next {
  width: 34px;
  height: 34px;
}
}

.featured-shows .slick-next {
  right:0px;
}


.featured-shows .slider-custom-icon {
  width: 34px;
  height: 34px;
}


.slider-custom-icon:hover,
.slider-custom-icon:hover {
  filter: grayscale(100%) brightness(96%);
  transition: filter 0.3s ease;
}



.featured-shows .show-card-wrapper {
  padding: 5px; /* Odstęp wewnątrz każdego slajdu karuzeli */
  max-width: 100%; /* Ensure max-width does not exceed 100% */
  margin-top: 0px;
  padding-top: 0px;
}

.featured-shows .featured-show-card {
  border-radius: 10px;
  overflow: hidden; /* Aby respektować border-radius */
  width: 300px; /* Stała szerokość karty dla dużych ekranów */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 0px solid;
}

.featured-shows .show-image-layer {
  position: relative;
  height: 175px;
  
}

.featured-shows .show-image {
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-position: top;
  
}

.featured-shows .user-rating-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.featured-shows .user-rating-badge-star {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #101828;
  padding: 1px 6px 6px 5px;
  border-radius: 4px;
  height: 34px;
}

.featured-shows .rating-star-badge {
  width: 16px;
  height: auto;
  padding-top:8px;
  padding-left:1px;
}

.featured-shows .show-info-layer {
  border-radius: 10px;
  background: #FFF;
  padding: 10px;
  display: flex;
  flex-direction: column; /* Zmiana kierunku na kolumnowy */
}

.featured-shows .top-layer {
  display: flex;
  flex-direction: row; /* Układ wierszowy dla plakatu i detali */
  
}


.featured-shows .poster-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.featured-shows .show-poster {
  width: 47px;
  height: 60px;
  border-radius: 5px;
  margin-right: 10px;
}

.featured-shows .poster-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

/* Style dla plakatu w BestAllTime i BestPlayedPage */
.featured-shows .featured-show-card .show-poster {
  width: 47px;
  height: 60px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

/* Dodatkowe style dla skeleton loadera */
.featured-shows .skeleton-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(110deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 8px;
}

.poster-img.visible {
  opacity: 1;
}

.poster-img.hidden {
  opacity: 0;
}

.skeleton-loader.visible {
  opacity: 1;
}

.skeleton-loader:not(.visible) {
  opacity: 0;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.featured-shows .poster-img.visible {
  opacity: 1;
  z-index: 2;
}

.featured-shows .poster-img.hidden {
  opacity: 0;
  z-index: 1;
}

.featured-shows .show-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Pozwala szczegółom na wypełnienie dostępnej przestrzeni */
  height: 60px;
  justify-content: center;
}

.featured-shows .rating-line-wrapper {
  display: flex;
  align-items: center;
}

.featured-shows .rating-wrapper {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.featured-shows .rating-star {
  width: 16px;
  height: auto;
  margin-right: 5px;
}

.featured-shows .show-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1px;
}

.featured-shows .theater-name {
  font-size: 12px;
  color: #475467;
  margin-bottom: 2px;
}

.featured-shows .average-rating {
  font-size: 12px;
  color: #475467;
  margin-bottom: -2px;
}

.featured-shows .show-description {
  font-size: 12px;
  color: #101828;
  width: 100%; /* Opis zajmuje całą szerokość */
  margin-top: 10px; /* Odstęp od górnej warstwy */
  margin-bottom: 5px;
  min-height:53px;
}


.this-season  .calendar-icon {
  margin-right: 5px; /* Odstęp między ikoną a tekstem */
}


.this-season  .show-date img {
  width: 13px;
  position: relative;
  top:-1px;
}

.this-season .show-date {
  color: #000;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 400;
  display:flex;
  text-align: left;
  justify-content: start;
  margin-bottom: 2px;
}


.this-season .show-card-wrapper {
  padding: 5px; /* Odstęp wewnątrz każdego slajdu karuzeli */
  max-width: 100%; /* Ensure max-width does not exceed 100% */
  margin-top: 0px;
  padding-top: 0px;
  
}

.this-season .featured-show-card {
  border-radius: 10px;
  overflow: hidden; /* Aby respektować border-radius */
  width: 170px; /* Stała szerokość karty dla dużych ekranów */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 0px solid;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.this-season .show-image-layer {
  position: relative;
  height: 255px;
  overflow: hidden;
  
}

.this-season .show-image {
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-position: top;
  transition: transform 0.3s ease;
}


.this-season .user-rating-badge2 {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.this-season .user-rating-badge-star2 {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #101828;
  padding: 1px 6px 6px 5px;
  border-radius: 4px;
  height: 34px;
}

.this-season .rating-star-badge2 {
  width: 16px;
  height: auto;
  padding-top:8px;
  padding-left:1px;
}

.this-season .show-info-layer {
  border-radius: 10px;
  background: #FFF;
  padding: 14px;
  display: flex;
  flex-direction: column; /* Zmiana kierunku na kolumnowy */
  transition: background 0.35s ease;
}

.this-season .show-card-wrapper:hover .show-image {
  transform: scale(1.03);
}

.this-season .show-card-wrapper:hover .featured-show-card {
  box-shadow: 0 4px 10px rgba(105, 65, 198, 0.07);
}

/* .this-season .featured-show-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 20px rgba(105, 65, 198, 0.12);
} */

.this-season .show-info {
  position: relative;
  text-align: left;
  display: flex;
  height: 154px;
  justify-content: space-between;
  flex-direction: column;
}

.this-season .top-layer {
  display: flex;
  flex-direction: row; /* Układ wierszowy dla plakatu i detali */
  
}

.this-season .show-poster {
  width: 47px;
  height: 60px;
  border-radius: 5px;
  margin-right: 10px;
  
}

.this-season .show-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Pozwala szczegółom na wypełnienie dostępnej przestrzeni */
  height: 60px;
  justify-content: center;
}

.this-season .rating-wrapper {
  display: flex;
  align-items: center;
}

.this-season .rating-star {
  width: 16px;
  height: auto;
  margin-right: 5px;
}

.this-season .show-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
  margin-top: 8px;
}

.this-season .theater-name {
  font-size: 12px;
  color: #475467;
  margin-bottom: 5px;
  line-height: 15px;
  padding: 0;


}

.this-season .theater-location {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  line-height: 15px;
}

.this-season .show-tags .tag {
  margin-top: 12px;
}



  
  
  
/* SECTION - My shows  */

.my-shows .show-tabs,
.personal-activities .show-tabs {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px; /* Dolny margines */
  border-bottom: 1px solid #EAECF0; /* Obrys dolny */
  padding-bottom: 0px; /* Odstęp od dolnej krawędzi */
}


.my-shows .show-tabs button,
.personal-activities .show-tabs button {
  margin: 0 10px;
  padding: 11px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #101828;
  background: none;
  border: none;
  border-bottom: 4px solid transparent;
  border-radius: 0px;
  text-decoration: none;
}

.my-shows .show-tabs button:hover,
.personal-activities .show-tabs button:hover,
.my-shows .show-tabs button.active,
.personal-activities .show-tabs button.active {
  border-bottom: 4px solid #6941C6;
  cursor: pointer;
}

.my-shows .shows-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -10px; /* ujemny margines lewy dla całej siatki, połowa wartości gap */
  margin-right: -10px; /* ujemny margines prawy dla całej siatki, połowa wartości gap */
  margin-bottom: 10px;
}

.my-shows .show-card {
  flex: 0 0 calc(33.333% - 20px); /* podstawowa szerokość */
  max-width: calc(33.333% - 20px); /* maksymalna szerokość */
  min-width: 95px; /* minimalna szerokość */
  position: relative;
  margin: 10px; /* połowa wartości gap dla każdej karty */
  cursor: pointer;
}



.my-shows .show-card .show-poster {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden; /* Zapobiega wychodzeniu obrazu poza kontener */
}

.my-shows .show-card .show-poster::before {
  content: "";
  display: block;
  padding-top: 150%; /* Stosunek proporcji 3:2 */
}

.my-shows .show-card .show-poster img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Zachowuje proporcje obrazu */
  border-radius: 10px; /* Opcjonalnie, jeśli chcesz zaokrąglone rogi */
}

.my-shows .show-card .show-info {
  position: relative;
  text-align: center;
}

.my-shows .user-rating-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.my-shows .user-rating-badge-star {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #101828;
  padding: 1px 6px 6px 5px;
  border-radius: 5px;
  height: 34px;
}

.my-shows .rating-star-badge {
  width: 16px;
  height: auto;
  padding-top: 4px;
  padding-left: 1px;
}

.my-shows .show-title {
  color: #000;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 6px;
}

.my-shows .show-date {
  color: #000;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 400;
}

.my-shows .remove-button {
  width: 100%;
  font-size: 12px;
  height: auto;
  padding: 4px 0px;
  margin-top: 12px;
}

.my-shows .add-again-button {
  width: 100%;
  font-size: 12px;
  height: auto;
  padding: 7px 0px;
  margin-top: 12px;
  line-height: 16px;
}

@media (max-width: 600px) {
  .my-shows .add-again-button {
    letter-spacing: -0.05rem;
  }
}


/* ... (wcześniejsze style) */

.my-shows .calendar-icon {
  margin-right: 5px; /* Odstęp między ikoną a tekstem */
}


.my-shows .show-date img {
  width: 13px;
  position: relative;
  top:-1px;
}



.my-shows .add-show-button,
.stats-rank .add-show-button {
  margin-top: 10px; /* Odstęp między siatką a przyciskiem */
  width: 100%;
  margin: 0 auto 10px;
}

.center-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.all-shows-info {
  color: #667085;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 20px;
  margin-bottom: 10px;
}

.more-wishlist-info {
  margin-top: -2px;
  margin-bottom: 7.5px; /* Obniżono wysokość o pół piksela */
}

.all-shows-info img {
  width:18px;
  filter: invert(44%) sepia(11%) saturate(749%) hue-rotate(183deg) brightness(93%) contrast(85%);
  margin-right: 10px;
  position: relative;
  top: -1px;
}



/* Media query for larger screens */
@media (min-width: 528px) {
  .my-shows .show-card,
  .stats-rank .show-card {
    flex: 0 0 calc(25% - 20px); /* podstawowa szerokość */
    max-width: calc(25% - 20px); /* maksymalna szerokość */
  }

  .shows-seen .show-card {
    max-width: 104px !important; /* Set a fixed width for larger screens */
  }

}

@media (max-width: 700px) {
  .my-shows .my-shows-buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

@media (min-width: 700px) {

  .my-shows-buttons {
    display: flex;
    justify-content: center;
    gap: 12px;
  }

  .my-shows-buttons .quick-icon {
    width: 22px;
    height: 22px;
  }


  .quick-links .quick-button {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .my-shows .add-show-button {
    width: auto;
  
  }
  .best-played .see-rank-button,
  .hp-see-calendar-button {
    width:auto;
  }

  .best-played-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -10px; /* ujemny margines lewy dla całej siatki, połowa wartości gap */
    margin-right: -10px; /* ujemny margines prawy dla całej siatki, połowa wartości gap */
    margin-bottom: 0px;
  }

  .best-played .show-card {
    display: flex;
    border: 0.5px solid #EAECF0;
    background: #fff;
    padding: 12px;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    flex: 0 0 calc(50% - 20px); /* podstawowa szerokość */
    max-width: calc(50% - 20px); /* maksymalna szerokość */
    min-width: 95px; /* minimalna szerokość */
    position: relative;
    margin: 10px; /* połowa wartości gap dla każdej karty */
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .best-played .show-card:hover {
    /* background: #F9FAFB; */
    transform: translateY(-2px);
    box-shadow: 0 5px 20px rgba(105, 65, 198, 0.12);
  }
  

  .my-shows .show-card,
  .stats-rank .show-card {
    flex: 0 0 calc(20% - 20px); /* podstawowa szerokość */
    max-width: calc(20% - 20px); /* maksymalna szerokość */
  }
}

@media (max-width: 1060px) {
  .nav-username {
    display: none;
  }
}

@media (max-width:920px) {
  .desktop-menu {
    gap:6px;
  }
  .btn-top-nav,
  .signup-desktop {
    padding: 7px 12px 7px 11px;
    gap: 8px;
    flex: 1 0 auto;
  }

  .dropdown-menu.show {
    right: 0;
    left: auto;
    text-align: left;
  }
  .explore-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    z-index: 9999;
  }
  
}

@media (min-width: 992px) {

  .best-played .show-card {
    flex: 0 0 calc(33.333% - 20px); /* podstawowa szerokość */
    max-width: calc(33.333% - 20px); /* maksymalna szerokość */
  }



  .quick-links .quick-button {
    flex-direction:row;
  }

  .show-page .master-show-card,
  .person-page .master-show-card {
    margin: 0 auto !important;
    margin-bottom: 16px !important;
    top: -140px !important;
  }


  .show-page .master-show-header {
    width: 100%;
    height: 400px !important;
  }

  .person-page .master-show-header {
    width: 100%;
    height: auto !important;
  }

  .no-relative-position {
    margin-top: -134px !important; /* Opcjonalnie resetuje marginesy */
  }

  .my-shows .show-card,
  .stats-rank .show-card {
    flex: 0 0 calc(11% - 20px); /* podstawowa szerokość */
    max-width: calc(11% - 20px); /* maksymalna szerokość */
  }

  .my-and-activity .my-shows .show-card {
    flex: 0 0 calc(16.6667% - 20px); /* podstawowa szerokość */
    max-width: calc(16.6667% - 20px); /* maksymalna szerokość */
  }
}

@media (min-width: 1600px) and (max-width: 1805px) {
  .my-and-activity .my-shows .show-card {
    margin: 6px;
  }
}

@media (min-width: 1600px) and (max-width: 1690px) {
  .my-and-activity .my-shows .show-card {
    min-width: 30px;
  }
}

@media (min-width: 1015px) and (max-width: 1320px) {
  .my-and-activity .my-shows .show-card {
    margin: 6px;
  }
}

@media (min-width: 1015px) and (max-width: 1240px) {
  .my-and-activity .my-shows .show-card {
    min-width: 30px;
  }
}


.show-page .master-show-card-desktop,
.person-page .master-show-card-desktop {
  display: flex;
  align-items: center;
  border: none;
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px;
  z-index: 10;
}



.show-page .master-additional-desktop,
.person-page .master-additional-desktop {
  display: flex;
  gap: 24px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 12px;
  min-width: 300px;
  flex-direction: column;
  justify-content: flex-end;
}

@media ( min-width: 1900px ) {
  .show-page .master-show-card-desktop,
  .person-page .master-show-card-desktop {
    padding: 24px;
  }
  
  .show-page .master-additional-desktop,
  .person-page .master-additional-desktop {
    padding: 24px;
  }

  .padding-12 {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.show-page .master-additional-desktop .btn,
.person-page .master-additional-desktop .btn {
  width: 100%;
}

@media ( min-width: 768px ) {

  .homepage .greeting-section,
  .homepage .featured-shows {
    display: none;
  }

  .show-page .master-show-header {
    height: 520px !important;
  }

  .person-page .master-show-header {
    height: 360px !important;
  }
  
  .show-page .master-show-background,
  .person-page .master-show-background {
    object-position: 50% 50% !important; /* Ustawienie pozycji obrazu */
  }
  
  .image-gradient {
    height: 70% !important;
    background: linear-gradient(180deg, rgba(16, 24, 40, 0) 0%, rgba(16, 24, 40, 0.8) 30%, #101828 60%, #101828 100%) !important;
  }

  .show-page .master-show-card,
  .person-page .master-show-card {
    display: none !important;
  }

  .show-page .no-show-mobile,
  .person-page .no-show-mobile {
    display: none !important;
  }

  .no-relative-position {
    margin-top: 10px !important; /* Opcjonalnie resetuje marginesy */
  }

  .show-page .master-show-poster, 
  .person-page .master-show-poster {
    position: relative;
    margin-right: 45px !important;
  }
  
  .show-page .master-show-poster .poster-img,
  .person-page .master-show-poster .poster-img {
    height: 280px !important; /* Fixed height */
  }

  .show-page .ranking a {
    color: #ffffff !important;
  }
  
  .show-page .show-info-basic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    color: #fff;
  }
  
  .show-page .ranking {
    display: flex;
    align-items: center;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 400;
    margin-bottom: 4px;
    flex-wrap: wrap;
  }
  
  .show-page .rank-icon {
    width: 13px !important;
    margin-right: 5px;
    margin-top: -1px;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(0deg) brightness(200%) contrast(100%);

  }
  
  .show-page .show-title{
    color: #fff !important;
    font-size: 36px !important;
    font-weight: 700 !important;
  }

  .person-page .person-name {
    color: #fff !important;
    font-size: 36px !important;
    font-weight: 700 !important;
  }
  
  .show-page .theater-info {
    color: #EAECF0 !important;
    font-size: 16px !important;
  }
  
  .show-page .location {
    color: #98A2B3 !important;
    font-size: 16px !important;
  }

  .show-page .location a {
    color: #98A2B3 !important;
  }
  
  .show-page .rating {
    color: #fff !important;
    font-size: 30px !important;
  }
  
  .show-page .rating-star {
    width: 24px !important;
    margin-right: 6px !important;
    margin-top: -2px !important;
  }
  
  .show-page .tags,
  .person-page .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 4px; /* Odstęp między tagami */
  }
  
  .show-page .tag,
  .person-page .tag {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: var(--Primary-50, #F9F5FF);
    color: var(--Primary-700, #6941C6);
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px; /* 150% */
  }


}

.show-page .tag-rewards,
.person-page .tag-rewards {
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--Primary-50, #FFFAEB);
  color: var(--Primary-700, #B54708);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px; /* 150% */    
  margin-top:5px;
}

.show-page .tag-rewards-mobile,
.person-page .tag-rewards-mobile {

  margin-top: 8px;


}

.show-page .tag-rewards-mobile .tag-rewards,
.person-page .tag-rewards-mobile .tag-rewards {
  width: 100%;
}

@media ( max-width: 767px ) {

  .show-page .master-show-card-desktop,
  .person-page .master-show-card-desktop {
    display: none !important;
  }

  .person-page .master-show-header {
    height: 80px !important;
  }

  .person-page .relative-reset {
    position: relative;
    margin-top: -60px;
  }

  .homepage .homepage-master-show-header {
    display: none;
  }

  .homepage {
    overflow-x: hidden;
  }
}


@media (min-width: 1440px) {


  .shows-seen .show-card {
    max-width: 112px !important; /* Set a fixed width for larger screens */
  }
}




  /* SECTION - Best played */
.best-played .best-of-currently-played {
    color: #333;
    background-color: #fff;
    padding: 20px 0;
  }

  
  
  .best-played .location-links,
  .weekly-section .location-links {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px; /* Dolny margines */
    border-bottom: 1px solid #EAECF0; /* Obrys dolny */
    padding-bottom: 0px; /* Odstęp od dolnej krawędzi */
  }
  
  
  .best-played .location-links button,
  .weekly-section .location-links button {
    margin: 0 10px;
    padding: 11px 15px;
    font-size: 16px;
    font-weight: 600;
    color: #101828;
    background: none;
    border: none;
    border-bottom: 4px solid transparent;
    border-radius: 0px;
    text-decoration: none;
  }
  
  .best-played .location-links button:hover,
  .best-played .location-links button.active,
  .weekly-section .location-links button.active,
  .weekly-section .location-links button:hover {
    border-bottom: 4px solid #6941C6;
    cursor: pointer;
  }


  
  .best-played .show-card {
    display: flex;
    align-items: center;
    border: none;
    overflow: hidden;
  }

  @media (max-width: 768px) {
    .best-played .show-card {
      margin-bottom: 16px;
    }
  }

  .best-played .show-poster {
    position: relative;
    margin-right: 17px;
  }
  
  .best-played .show-poster .poster-img {
    height: 200px; /* Fixed height */
    width: auto; /* Auto width to maintain aspect ratio */
    object-fit: cover; /* Ensures the image covers the area without distorting */
    object-position: center; /* Centers the image in the frame */
    border-radius: 10px; /* Rounded corners */
    aspect-ratio: 2 / 3; /* Maintains a 3:2 aspect ratio */
  }

  .admin-poster {
    height: 100px; /* Fixed height */
    width: auto; /* Auto width to maintain aspect ratio */
    object-fit: cover; /* Ensures the image covers the area without distorting */
    object-position: center; /* Centers the image in the frame */
    border-radius: 8px; /* Rounded corners */
    aspect-ratio: 2 / 3; /* Maintains a 3:2 aspect ratio */
  }

  .admin-panel .classification label {
    margin-top: 3px;
  }

  .admin-duplicate {
    text-align: right;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: end;
    justify-content: end;
  }

  .addcast-modal .modal-content {
    max-width: 100% !important;
  }

  .addcast-modal .modal-dialog {
    max-width: 80% !important;
  }

  .best-played .under-ranking {
    position: absolute;
    right: -20px;
    font-size: 240px;
    font-weight: 900;
    color: #F2F4F7;
    display:none;

  }

  .best-played .above-ranking {
    position: absolute;
    bottom: -60px;
    right: -70px;
    padding: 0;
    margin: 0;
    font-weight: 900;
    font-size: 64px;
    color: white;
    z-index: 2;
    background: radial-gradient(ellipse at center, #101828 0%, transparent 60%, transparent 100%);
    width: 140px;
    height: 140px;
    display:none;
  }

  .best-played .above-ranking .rank-number {
    width: 40px;
    left:33px;
    top: 10px;
    position: absolute;
    text-align: center;
  }

  
  
  .best-played .show-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    z-index: 2;
  }

  .best-played .show-info-basic {
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 4px;
align-self: stretch;
  }
  
  .best-played .ranking {
    display: flex;
    align-items: center;
    color: var(--Gray-500, #667085);
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 4px;
    flex-wrap: wrap;
  }

  .person-page .show-info {
    margin-top: 18px;
    margin-bottom: 12px;
  }

  .best-played .rank-icon {
    width: 11px;
    margin-right: 5px;
    margin-top: -1px;
    color: #667085;
  }
  
  .best-played .show-title {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
  }
  
  .best-played .theater-info {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
  }
  
  .best-played .location {
    color: var(--Gray-500, #667085);
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
  }
  
  .best-played .rating {
    color: var(--Black, #000);
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  
  .best-played .rating-star {
    width: 19px;
    height: auto;
    margin-right: 5px;
    margin-top: -3px;
  }
  
  .best-played .tags,
  .discourse-latest .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 4px; /* Odstęp między tagami */
  }

  .show-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
  
  .tag {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: var(--Primary-50, #F9F5FF);
    color: var(--Primary-700, #6941C6);
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px; /* 150% */
  }

  /* Style podstawowe dla kategorii */
.tag-play {
  background-color: #EEF4FF !important;
  color: #3538CD !important;
}

.tag-musical {
  background-color: #F9F5FF !important;
  color: #6941C6 !important;
}

.tag-opera {
  background-color: #F8F9FC !important;
  color: #363F72 !important;
}

.tag-concert {
  background-color: #FDF2FA !important;
  color: #C11574 !important;
}

.tag-other {
  background-color: #F8F9FC !important;
  color: #363F72 !important;
}

/* Style dla tagów drugorzędnych */
.tag-secondary {
  opacity: 1;
}

/* Style dla tagów trzeciorzędnych */
.tag-tertiary {
  opacity: 1;
}

/* Style dla tagów czwartorzędnych */
.tag-quaternary {
  opacity: 1;
}
  
  .best-played .tag {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: var(--Primary-50, #F9F5FF);
    color: var(--Primary-700, #6941C6);
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px; /* 150% */
  }

  .discourse-latest .tag {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: var(--Primary-50, #F2F4F7);
    color: var(--Primary-700, #344054);
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px; /* 150% */
  }
  
  
  .best-played .user-rating-badge {
    position: absolute;
    top: 8px;
    left: 8px;
    background: #FFFFFF;
    padding: 4px 8px;
    border-radius: 4px;
    font-family: Inter;
    font-size: 12px;
    font-weight: bold;
    z-index: 3;
  }

  .best-played .user-rating-badge-star {
    position: absolute;
    top: 8px;
    left: 8px;
    background: #101828;
    padding: 1px 6px 6px 5px;
    border-radius: 4px;
    height: 26px;
    z-index: 3;
  }

  .best-played .rating-star-badge {
    width: 14px;
    height: auto;
    padding-left: 1px;
    margin-top:-4px;
  }
  

  .best-played .see-rank-button,
  .hp-see-calendar-button {
    margin-top: 0px; /* Odstęp między siatką a przyciskiem */
    width: 100%;
  }

  .show-tabs-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin; /* Firefox */
  }
  
  .show-tabs-container::-webkit-scrollbar {
    height: 6px;
  }
  
  .show-tabs-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
  
  .show-tabs-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  
  .tab {
    display: inline-block;
    padding: 10px 20px;
    white-space: nowrap;
  }
  
  
  /* Media query for larger screens */
  @media (min-width: 528px) {

    .best-played .see-rank-button,
    .hp-see-calendar-button {
      width: auto;
    }
  }
  
  
  /* Quick Links */
.quick-links .quick-button {
  width: 100%;
  margin: 0.5rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quick-links .quick-icon {
  width: 22px;
  height: 22px;
  color: #344054;
}


.benefits-list-desktop {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #D6BBFB;
  font-size: 14px;
  opacity: 0.8;
}

.benefits-list-desktop .benefit-item {
  opacity: 0;
  line-height: 1.5;
  display: flex;
  align-items: center;
}

.benefits-list-desktop .check-icon {
  filter: invert(80%) sepia(87%) saturate(295%) hue-rotate(74deg) brightness(94%) contrast(93%);

  width: 13px;
  height: 13px;
  margin-right: 8px;
}

.benefits-list {
  font-size: 14px;
  color: #7F56D9;
  font-weight: 600;
  max-height: 18px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  line-height: 1.4;
}

.benefits-list .check-icon-purple {
  filter: invert(35%) sepia(40%) saturate(1000%) hue-rotate(230deg) brightness(90%) contrast(95%);
  background-color: transparent; /* Przezroczyste tło */
  width: 16px;
  height: 16px;
  margin-top: 2px;
}

/* Media query for larger screens */
@media (min-width: 528px) {
  .quick-links .quick-button {
    width: auto;
  }
}

.login-page h1{
  text-align:inherit
}

.login-section {
  max-width: 450px;
}  

.login-section .quick-button {
  margin-top:20px;
  color: white;
}

@media (max-width: 600px) {
  .login-section {
    max-width: 100%;
    width:100%;
  }

  .login-page .quick-button {
    min-width:100% !important
  }
}

.login-page {
  text-align: center;
}

.google-col {
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width:100%;
}

.login-page .header-title {
  color: #101828;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 10px;
}

.login-page .header-subtitle {
  color: #475467;
  font-size: 14px;
  margin: 0;
  padding-top:20px;
  margin-bottom: 30px;
}

.login-page .header-subtitle2 {
  color: #475467;
  font-size: 0.8rem;
  opacity: 0.8;
  margin: 0;
  padding-top:3px;
  margin-bottom: 30px;
}

.login-page .quick-button {
  min-width:450px;
  margin: 0 auto;
}

@media (max-width: 768px) {
.login-page .quick-button {
  max-width:100%;
}
}

.google-button-div {
  width: 220px;
}


  /* Footer */

/* Style dla stopki */

.footer-container {
    margin-top: auto;
    margin-bottom: 0px;
}

/* Sekcja z logo, linkami i ikonami social mediów */
.footer-top {
  margin-bottom: 2rem;
  display: flex;
  gap: 10px;
}

.footer-logo {
  max-width: 150px;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 8px;
  margin-top: 18px;
}

.footer-link {
  color: #667085;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;

}

.footer-link:hover {
  text-decoration: underline;
}

.footer-social {
  display: flex;
  justify-content: center;
  gap: 14px;
}

.social-icon {
  width: 24px;
  height: 24px;
}

.social-icon:hover {
filter: invert(31%) sepia(20%) saturate(593%) hue-rotate(176deg) brightness(92%) contrast(87%);


}

/* Styl dla istniejącej sekcji stopki */
.footer p {
  color: var(--Gray-400, #98A2B3);
  text-align: center;
  margin: 0;
  font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}

  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .search-input {
      width: calc(100% - 60px); /* Adjust the width of the search input on smaller screens */
    }
  
    .search-button {
      width: 50px;
    }
  }
  
.btn-primary,
.btn-secondary,
.btn-light,
.btn-tertiary,
.btn-danger,
.btn-success {
  font-size: 16px;
  line-height: 22px;
}

 /* Nadpisanie domyślnych stylów dla btn-primary */
.btn-primary {
  display: flex;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Primary-600, #7F56D9);
  background-color: var(--Primary-600, #7F56D9);
  color: var(--White, #FFF);
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer; /* Upewnij się, że kursor jest "pointer" */
  text-decoration: none; /* Bez podkreślenia tekstu */
}

/* Styl dla stanu hover, focus i active */
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:disabled, 
.btn-primary:active {
  background-color: #6941C6 !important; /* Ciemniejszy fiolet przy hover i innych stanach */
  text-decoration: none; /* Bez podkreślenia przy hover */
  border: 1px solid var(--Primary-600, #6941C6);
}

/* Nadpisanie stylów focus i active odpowiedzialnych za niebieski kolor */
.btn-primary:focus,
.btn-primary:disabled, 
.btn-primary:active {
  outline: none; /* Usuwa niebieski obrys */
  box-shadow: none; /* Usuwa niebieski cień Bootstrapu */
}

/* Opcjonalnie możesz ustawić własny cień dla stanu focus lub active */
.btn-primary:focus {
  box-shadow: 0 0 0 4px rgba(127, 86, 217, 0.25); /* Delikatny fioletowy cień */
}


.btn-secondary {
  display: flex;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Primary-600, #F9F5FF);
  background-color: var(--Primary-600, #F9F5FF);
  color: var(--White, #6941C6);
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer; /* Ensure cursor is pointer */
  text-decoration: none; /* No text decoration */
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
  background-color: #F4EBFF; /* Darker purple on hover and other states */
  text-decoration: none; /* No text decoration on hover */
  color: var(--White, #6941C6);
  border: 1px solid var(--Primary-600, #F4EBFF);

}


.btn-light {
  display: flex;
padding: 16px 28px;
justify-content: center;
align-items: center;
gap: 12px;
flex: 1 0 0;
border-radius: 8px;
border: 1px solid var(--Gray-300, #D0D5DD);
background: var(--White, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: var(--Gray-700, #344054);

/* Text lg/Semibold */
font-family: Inter;
font-style: normal;
font-weight: 600;
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active {
  background-color: #F9FAFB; /* Darker purple on hover and other states */
  text-decoration: none; /* No text decoration on hover */
  border: 1px solid var(--Primary-600, #D0D5DD);

}

.btn-tertiary {
  display: flex;
padding: 16px 28px;
justify-content: center;
align-items: center;
gap: 12px;
flex: 1 0 0;
border-radius: 8px;
border: 0px solid var(--Gray-300, #D0D5DD);
background: transparent;
box-shadow: 0px;
color: var(--Gray-700, #6941C6);
font-family: Inter;
font-style: normal;
font-weight: 600;
}

.btn-tertiary:hover,
.btn-tertiary:focus,
.btn-tertiary:active {
  background-color: #F9F5FF; /* Darker purple on hover and other states */
  text-decoration: none; /* No text decoration on hover */
  border: 0px solid var(--Primary-600, #F9F5FF);
  color: var(--Gray-700, #6941C6);
}


.btn-danger {
  display: flex;
padding: 16px 28px;
justify-content: center;
align-items: center;
gap: 12px;
flex: 1 0 0;
border-radius: 8px;
border: 1px solid #FDA29B;
background: #fff;
color: #B42318 !important;

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: #344054;

/* Text lg/Semibold */
font-family: Inter;
font-style: normal;
font-weight: 600;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active {
  background-color: #FEF3F2; /* Darker purple on hover and other states */
  text-decoration: none; /* No text decoration on hover */
  border: 1px solid #FDA29B;
  color: #B42318;
}

.btn-danger:disabled {
  color: #98A2B3 !important;
  background-color: #F9FAFB;
  border-color: #F9FAFB;
}

.btn-success {
  display: flex;
padding: 16px 28px;
justify-content: center;
align-items: center;
gap: 12px;
flex: 1 0 0;
border-radius: 8px;
/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
/* Text lg/Semibold */
font-family: Inter;
font-style: normal;
font-weight: 600;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active {
  text-decoration: none; /* No text decoration on hover */
}


.btn-small {
padding: 16px 28px;
gap: 12px;
flex: 1 0 0;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 18px; /* 155.556% */
}

.btn-smallest {
  padding: 10px 16px;
  gap: 12px;
  flex: 1 0 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 155.556% */
  }
  
  .btn-top-nav {
    padding: 10px 15px 10px 14px;
    gap: 10px;
    flex: 1 0 auto;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 155.556% */
    background: #101828;
    border: 1px solid #101828;
    }

    .btn-top-nav:hover,
    .btn-top-nav:focus,
    .btn-top-nav:active {
  text-decoration: none !important; /* No text decoration on hover */
}

.btn-top-nav:hover {
  background: #1D2939 !important;
  border: 1px solid #1D2939
}

  .btn-q-desktop {
    padding: 8px 13px 8px 12px;
    gap: 10px;
    flex: 1 0 0;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 155.556% */
    }

    .btn-q-desktop img {
      width: 20px;
      height: 20px;
    }

    .btn-additional {
      padding: 8px 13px 8px 12px;
      gap: 10px;
      flex: 1 0 0;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 155.556% */
      width: 100%;
      }
  
      .btn-additional img {
        width: 20px;
        height: 20px;
      }

/* Ensure anchor tags do not add underlines to buttons */
a .btn, 
a .btn:hover, 
a .btn:focus, 
a .btn:active {
  text-decoration: none; /* Remove text-decoration from buttons inside anchor tags */

}
  
  /* Further customize buttons as needed... */
  


  /* Diary */

  /* Użyj stylów z sekcji My Shows */

  .diary-section {
    margin-bottom: 20px;

    border-radius: 10px;

    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .diary-section .quick-icon {
    background-color: transparent; /* Przezroczyste tło */
}

.diary-section .quick-icon.expand {
    filter: invert(26%) sepia(90%) saturate(1675%) hue-rotate(243deg) brightness(83%) contrast(91%);
    background-color: transparent; /* Przezroczyste tło */
}

  .diary-section-top {
    background: #fff;
    padding: 26px;
    border-radius: 9px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border: 1px solid var(--Gray-300, #DFE2E6);
    transition: background-color 0.3s ease; /* Płynne przejście */

  }

  .profile-container {
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid var(--Gray-300, #DFE2E6);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  
  .diary-section-top.collapsed {
    background-color: #F9F5FF; /* Styl dla zwiniętej sekcji */
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
    border: 1px solid #D6BBFB;

  }
  
  .diary-section-top.collapsed .section-title {
    color: #6941C6;

  }

  .diary-section-top.collapsed .section-subtitle {
    color: #6941C6;
    opacity: 0.7;
  }
  
  .diary-section-top.expanded {
    background-color: #fff; /* Styl dla rozwiniętej sekcji */
  }

  .diary-section-top.expanded:hover {
    background-color: #F9FAFB; /* Styl dla rozwiniętej sekcji */
    border: 1px solid #D0D5DD;
  }

  .diary-section-top.collapsed:hover {
    background-color: #F4EBFF; /* Styl dla rozwiniętej sekcji */

  }

  .diary-section-grid.expanded {
    border-bottom: 1px solid var(--Gray-300, #DFE2E6);
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
    border-left: 1px solid var(--Gray-300, #DFE2E6);
    border-right: 1px solid var(--Gray-300, #DFE2E6);
    margin-top: -8px;
    padding-top: 9px;
  }

  .diary-section-grid.collapsed {
    border-bottom: 0px solid var(--Gray-300, #DFE2E6);
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
    border-left: 0px solid var(--Gray-300, #DFE2E6);
    border-right: 0px solid var(--Gray-300, #DFE2E6);
  }

  .diary-section .section-title {
    color: #101828;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 6px;
  }

  .diary-section .section-subtitle {
    padding-bottom: 0px;
  }

.diary .shows-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 8px;
}


.diary .show-card {
  flex: 0 0 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  min-width: 95px;
  position: relative;
  margin: 8px;
}

.diary .show-poster {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
}

.diary .show-card .show-poster::before {
  content: "";
  display: block;
  padding-top: 150%;
}

.diary .show-card .show-poster img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.diary .show-info {
  position: relative;
  text-align: center;
}

.diary .user-rating-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}


.diary .user-rating-badge-star {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #101828;
  padding: 1px 6px 6px 5px;
  border-radius: 5px;
  height: 34px;
}


.diary .show-title {
  color: #000;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 6px;
}

.diary .show-date {
  color: #000;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 400;
}

.diary .calendar-icon {
  margin-right: 5px;
}

.diary .add-show-button {
  margin-top: 10px;
  width: 100%;
}

@media (min-width: 528px) {
  .diary .show-card {
    max-width: 95px;
    margin: 8px;
  }
  
}

@media (min-width: 700px) {

  .diary .add-show-button,
  .stats-rank .add-show-button {
    width: auto;
  }

}

.show-page,
.person-page {
  position: relative;
}

.show-page .get-tickets {
  width: 100%;
}


  /* buttons in showpage */
  .showpage-buttons button {
    width: 100%;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .showpage-buttons .quick-icon {
    width: 22px;
    height: 22px;
    color: #344054;
  }

.no-margin-row {
  margin-left: -16px;
  margin-right: -16px;
}

.show-page .master-show-header,
.person-page .master-show-header {
  position: relative;
  width: 100%;
  height: 205px;
  overflow: hidden;
  top: -24px;
  background-color: #101828; /* Kolor tła */

}

.homepage-master-show-header {
  overflow: hidden;
  top: -24px;
  background-color: #101828; /* Kolor tła */
  display: flex;

}

.hp-master-container {
  display: flex;
  flex-direction: row;
  color: white;
  gap: 120px;
  align-items: center;
  padding: 24px 0;
  justify-content: space-between;
  width: 100%;
  min-height:435px;
}

.hp-master-left {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.hp-master-right {
  display: flex;
  width: 700px;
  flex: 1;
}

.greeting-section-desktop {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 0.7rem;
  margin-bottom: 1rem;
}

.greeting-section-desktop .header-title {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
}

.greeting-section-desktop .header-subtitle {
  color: #ffffff;
  font-size: 1rem;
  margin: 0;
  padding-top:6px;
  padding-bottom: 8px;
  font-weight: 400;
}

.greeting-section-desktop button {
  color: #667085
}

.desktop-quick-links {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.d-q-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

.d-q-buttons .quick-button:hover {
  background: #D0D5DD;
  color: #1D2939 !important;
}

.d-q-header {
  font-size: 16px;
  font-weight: 600;
}

.d-q-buttons .btn {
  flex: 0 0 auto;
}

.d-q-buttons .add-show-button {
  color: #fff !important;
}

.d-q-buttons .add-show-button img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(0deg) brightness(200%) contrast(100%);
}

.d-q-buttons .quick-button {
  color: #344054 !important;
}

.featured-shows-desktop .slick-slider {
  margin: 0 !important;
}




.featured-shows-desktop .slick-prev {
  left: 20px;
}

@media (min-width: 769px) {

.trending-section .slick-prev{
  left: 32px;
}

.trending-section .slick-next{
  right: 36px;
}

.this-season .slick-prev {
  left: 22px;
}

.this-season .slick-next {
  right: 16px;
}
}

.featured-shows-desktop .slick-next {
  right:20px;
}

.featured-shows-desktop .slider-custom-icon,
.trending-section .slider-custom-icon,
.this-season .slider-custom-icon {
  width: 34px;
  height: 34px;
}

.featured-shows-desktop .slider-custom-icon:hover {
  filter: grayscale(100%) brightness(96%);
  transition: filter 0.3s ease;
}

.featured-shows-mobile {
  height: 328px;
}

.featured-shows-desktop {
  width: 700px;
  height: 388px;
  padding: 0;
}

.featured-shows-desktop .show-card-wrapper {
  width: 700px; /* Stała szerokość karty dla dużych ekranów */

  margin-top: 0px;
  padding-top: 0px;
}

.featured-shows-desktop .featured-show-card {
  border-radius: 10px;
  overflow: hidden; /* Aby respektować border-radius */
  max-width: 700px; /* Stała szerokość karty dla dużych ekranów */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 0px solid;
  background: #1D2939;
  height: 388px;
}

.featured-shows-desktop .show-image-layer {
  position: relative;
  height: 1000px;
  overflow: hidden;
}

.featured-shows-desktop .show-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-position: 50% 30%;
  transition: transform 0.3s ease;
}

.featured-shows-desktop .featured-show-card:hover .show-image {
  transform: scale(1.05);
}

.featured-shows-desktop .featured-show-card:hover .show-info-layer {
  background: #1A2533;
}

.poster-img {
  will-change: opacity;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.poster-container {
  contain: content;
}

.featured-shows-desktop .user-rating-badge {
  position: absolute;
  top: 14px;
  left: 14px;
  background: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.featured-shows-desktop .user-rating-badge-star {
  position: absolute;
  top: 14px;
  left: 14px;
  background: #101828;
  padding: 1px 6px 6px 5px;
  border-radius: 4px;
  height: 34px;
}

.featured-shows-desktop .rating-star-badge {
  width: 16px;
  height: auto;
  padding-top:8px;
  padding-left:1px;
}

.featured-shows-desktop .show-info-layer {
  border-radius: 10px;
  background: #1D2939;
  padding: 14px;
  display: flex;
  flex-direction: column; /* Zmiana kierunku na kolumnowy */
  color: #fff;
  display: flex;
  justify-content: center;
  transition: background 0.3s ease;
}

.featured-shows-desktop .top-layer {
  display: flex;
  flex-direction: row; /* Układ wierszowy dla plakatu i detali */
  gap: 16px;
  align-items: center;
}



.featured-shows-desktop .poster-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.featured-shows-desktop .show-poster {
  width: 86px;
  height: 123px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.featured-shows-desktop .poster-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

/* Dodatkowe style dla skeleton loadera */
.featured-shows-desktop .skeleton-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #101828;
  background: linear-gradient(110deg, #344054 8%, #667085 18%, #344054 33%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 8px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.featured-shows-desktop .poster-img.visible {
  opacity: 1;
  z-index: 2;
}

.featured-shows-desktop .poster-img.hidden {
  opacity: 0;
  z-index: 1;
}

.featured-shows-desktop .show-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Pozwala szczegółom na wypełnienie dostępnej przestrzeni */
  justify-content: center;
  color: #fff;
  position: relative;
}

.featured-shows-desktop .rating-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 0px;
  padding-right:6px;
}

.featured-shows-desktop .rating-star {
  width: 23px;
  height: auto;
  margin-right: 6px;
}

.featured-shows-desktop .show-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 4px;
}

.featured-shows-desktop .theater-name {
  font-size: 13px;
  color: #fff;
  margin-right: 4px;
  
}

.featured-shows-desktop .average-rating {
  font-size: 28px;
  color: #fff;
  margin-bottom: -2px;
}

.featured-shows-desktop .show-description {
  font-size: 13px;
  color: #fff;
  width: 100%; /* Opis zajmuje całą szerokość */
  margin-top: 4px; /* Odstęp od górnej warstwy */
  margin-bottom: 0px;
  opacity: 0.8;
}

@media (min-width: 1600px) and (max-width: 1899px) {
  .featured-shows-desktop {
    width: 600px;
    height: 368px;
  }
  
  .featured-shows-desktop .show-card-wrapper {
    max-width: 600px; /* Stała szerokość karty dla dużych ekranów */
  }

  .featured-shows-desktop .featured-show-card {
    height: 368px;
  }

  .featured-shows-desktop .show-poster {
    width: 70px;
    height: 105px;
  }
}

@media (max-width: 1899px) {
  .hp-master-container {
    gap: 70px;
  }
}

@media (max-width: 1200px) {
  .featured-shows-desktop {
    width: 540px;
    height: 370px;
  }
  
  .featured-shows-desktop .show-card-wrapper {
    max-width: 540px; /* Stała szerokość karty dla dużych ekranów */
  }
  .featured-shows-desktop .featured-show-card {
    height: 370px;
  }

  .featured-shows-desktop .show-poster {
    width: 70px;
    height: 105px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hp-master-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    padding: 24px 0;
    width: 100%;
  }
  .hp-master-left {
    width: 100%;
  }
  .hp-master-right {
    width: 100%;
    max-width:100%;
  }
  .featured-shows-desktop {
    width: 100%;
  }
  
  .featured-shows-desktop .show-card-wrapper {
    padding-right: 20px;
  }
}


.background-color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #101828; /* Kolor tła do krawędzi przeglądarki */
  z-index: 1;
}

.image-wrapper {
  position: relative;
  max-width: 1320px; /* Maksymalna szerokość dla obrazka */
  margin: 0 auto;
  height: 100%;
  z-index: 2;
}

.show-page .master-show-background,
.person-page .master-show-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 30%; /* Ustawienie pozycji obrazu */
}

.image-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70%; /* Zwiększamy wysokość gradientu */
  background: linear-gradient(180deg, rgba(16, 24, 40, 0) 10%, rgba(16, 24, 40, 0.8) 50%, #101828 90%);
  z-index: 3;
}

/* Media query dla rozdzielczości 992px i wyższych */
@media (min-width: 992px) {
  .background-color-overlay {
    width: 100vw;
    left: calc((100% - 1200px) / 2 * -1); /* Rozciąganie tła na pełną szerokość przeglądarki */
  }
}

.show-page .master-show-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  border: none;
  position: relative;
  top: -84px; /* 80% of 205px = ~164px, adjusted slightly */
  background: #fff;
  border-radius: 10px;
  padding: 12px;
  padding-left: 0px;
  padding-right: 0px;
  z-index: 10;
}

.person-page .master-show-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  border: none;
  position: relative;
  top: -70px; /* 80% of 205px = ~164px, adjusted slightly */
  background: #fff;
  border-radius: 10px;
  padding: 12px;
  padding-left: 0px;
  padding-right: 0px;
  z-index: 10;
}

.pos-relative {
  position: relative;
}


.show-page .master-show-poster,
.person-page .master-show-poster {
  position: relative;
  margin-right: 17px;
}

.show-page .master-show-poster .poster-img,
.person-page .master-show-poster .poster-img {
  height: 200px; /* Fixed height */
  width: auto; /* Auto width to maintain aspect ratio */
  object-fit: cover; /* Ensures the image covers the area without distorting */
  object-position: center; /* Centers the image in the frame */
  border-radius: 8px; /* Rounded corners */
  aspect-ratio: 2 / 3; /* Maintains a 3:2 aspect ratio */
}

.show-page .user-rating-badge {
  position: absolute;
  top: 8px;
  left: 8px;
  background: #FFFFFF;
  padding: 4px 8px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 12px;
  font-weight: bold;
}


.show-page .user-rating-badge-star {
  position: absolute;
  top: 8px;
  left: 8px;
  background: #101828;
  padding: 1px 6px 6px 5px;
  border-radius: 5px;
  height: 26px;
}

.show-page .rating-star-badge {
  width: 14px;
  height: auto;
  padding-top: 0px;
  margin-top:-3px;
  padding-left: 1px;
}

.show-page .show-info,
.person-page .show-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  padding-right: 12px;

}

.person-page .show-info2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: center;
  padding-right: 12px;
  gap: 20px;

}

.show-page .show-info-basic,
.person-page .show-info-basic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.show-page .ranking {
  display: flex;
  align-items: center;
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
  flex-wrap: wrap;
}

.show-page .ranking a {
  color: var(--Gray-500, #667085);
}

.show-page .ranking a:hover {
  text-decoration: underline;
}

.show-page .rank-icon {
  width: 11px;
  margin-right: 5px;
  margin-top: -1px;
  color: #667085;
}

.show-page .show-title {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
}

.person-page .person-name {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
}

.show-page .theater-info {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}

.show-page .location {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}

.show-page .location a {
  color: var(--Gray-500, #667085);
}

.show-page .location a:hover {
  text-decoration: underline;
}

.show-page .rating {
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.show-page .rating-star {
  width: 19px;
  height: auto;
  margin-right: 5px;
  margin-top: -3px;
}

.show-page .tags,
.person-page .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px; /* Odstęp między tagami */
}

.show-page .tag,
.person-page .tag {
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--Primary-50, #F9F5FF);
  color: var(--Primary-700, #6941C6);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px; /* 150% */
}

.show-page .add-show-button {
  width: 100%;
}

.awards-modal {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.awards-modal .award-title {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;

}

.awards-modal .award-details {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
}

.awards-modal .award-details2 {
  color: #000;
  opacity: 0.5;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  margin-top: 2px;
}

.awards-modal .award-type {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}

@media (min-width: 700px) {

  .show-page .add-show-button {
    width: auto;
  }
}

.no-relative-position {
  position: static; /* Przeciwdziała position: relative */
  top: 0; /* Zresetowanie wszelkich przesunięć */
  margin-top: -79px; /* Opcjonalnie resetuje marginesy */
}

/* Star rating */
.my-rating .star-rating {
  display: flex;
  gap: 4px;
  margin-top: 12px;
}



.star-icon {
  width: 28px;
  height: auto;
  font-size: 28px;
}

.yellow-star {
  filter: grayscale(0%);
}

.gray-star {
  filter: grayscale(100%);
}

.my-rating .rating-summary-p {
  color: #475467;
  margin-bottom: 4px;
  
}

.delete-wishlist {
  color: #475467;
  margin-bottom: 0px;
}

.note-button {
  background: none;
  border: none;
  color: #475467;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  font-size:13px;
}

@media (max-width: 768px) {
  .note-textarea {
    font-size:16px !important;
  }
}

.note-button img {
  width: 18px;
  margin-right: 10px;
  margin-top:-2px;
  filter: invert(33%) sepia(13%) saturate(858%) hue-rotate(176deg) brightness(87%) contrast(89%);
}

.note-textarea {
  width: 100%;
  margin-top: 16px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  font-size: 13px;
}


.upcoming {
  color: #039855;
  text-transform: uppercase;
  font-size: 11px !important;
  font-weight: 700;
}

.shows-seen .shows-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -5px; /* ujemny margines lewy dla całej siatki, połowa wartości gap */
  margin-right: -5px; /* ujemny margines prawy dla całej siatki, połowa wartości gap */
  margin-bottom: -3px;
}

.shows-seen .show-card {
  flex: 0 0 calc(25% - 20px); /* podstawowa szerokość */
  max-width: calc(25% - 20px); /* maksymalna szerokość */
  min-width: 80px; /* minimalna szerokość */
  position: relative;
  margin: 5px; /* połowa wartości gap dla każdej karty */
  cursor: pointer;
}

.shows-seen .show-date img {
  width: 11px;
  position: relative;
  top:-1px;
  margin-right:1px;
  filter: invert(45%) sepia(21%) saturate(404%) hue-rotate(182deg) brightness(90%) contrast(86%);
}

@media (max-width: 600px) {
.shows-seen .show-date img {
  width: 9px;
}
}


.shows-seen .show-card .show-poster {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden; /* Zapobiega wychodzeniu obrazu poza kontener */
}

.shows-seen .show-card .show-poster::before {
  content: "";
  display: block;
  padding-top: 150%; /* Stosunek proporcji 3:2 */
}

.shows-seen .show-card .show-poster img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Zachowuje proporcje obrazu */
  border-radius: 8px; /* Opcjonalnie, jeśli chcesz zaokrąglone rogi */
}

.shows-seen .show-date {
  margin-top: 4px;
  font-size: 12px;
  color: #667085;
  text-align: center;
  margin-bottom: 0px;
}

.shows-seen .show-date p {
  font-size:12px;
  margin: 0;
  padding: 0;
}

.info-table {
  width: 100%;
  font-size: 14px;
}

.info-row {
  display: flex;
  gap: 10px;
  padding: 14px 0;
  border-bottom: 1px solid #eaecf0;
}

.info-row:nth-child(odd) {
  background-color: #f9fafb;
}

.info-row:nth-child(even) {
  background-color: #fff;
}

.info-row:last-child {
  border-bottom: 0px solid #fff;
}

.info-icon {
  width: 40px; /* Możesz dostosować szerokość do swoich potrzeb */
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top:3px;
  margin-right: 0px;
}

.info-icon img {
  width:15px;
  filter: invert(69%) sepia(11%) saturate(424%) hue-rotate(179deg) brightness(92%) contrast(88%);

}

.info-name {
  flex: 1;
  font-weight: bold;
  word-wrap: break-word; /* Zawijanie tekstu, jeśli jest zbyt długi */
  white-space: normal; /* Pozwala na zawijanie tekstu */
  color: #98A2B3;
}

.info-content {
  flex: 2;
}


/* Animacja rozpuszczenia */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);

  }
  to {
    opacity: 1;
    transform: translateY(0);

  }
}

/* Styl dla modala wyszukiwarki */
.search-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #F9FAFB;
  z-index: 1000;
  overflow-y: auto;
  animation: fadeIn 0.25s ease forwards; /* Dodajemy animację przy otwarciu */
}

@media (max-width: 500px) {
  .search-modal {
    padding-bottom: 90px;
  }
  .stats-rank .my-shows-buttons {

    width: 100%;
  }
}

.search-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
  animation: fadeIn 0.25s ease forwards; /* Dodajemy animację dla overlay */
}

.search-modal-header-container {
  display: flex;
  border-bottom: 0.5px solid #ccc;
  padding: 20px;
  margin-bottom: 0px;
  position: relative;
  background: #fff;
  align-items: center;
  justify-content: center;
}

.search-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.search-input-group {
  position: relative;
  width: 100%;
}

.search-input-group {
  border-radius: 10px;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: 50px;
}

.search-input-group .form-control,
.search-input-group .input-group-text {
  border-top-right-radius: 10px !important; /* Przykład - zmień na swoje potrzeby */
  border-bottom-right-radius: 10px !important; /* Przykład - zmień na swoje potrzeby */
}

.search-input-group .form-control:focus {
  border-color: #6941C6; /* Zmien kolor na wybrany */
  box-shadow: 0 0 5px rgba(105, 65, 198, 0.5);
  outline: none; /* Usuń domyślne obramowanie przeglądarki */
}



.search-icon {
  position: absolute;
  background: transparent;
  border-left: none;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  pointer-events: none; /* Upewnij się, że kliknięcia nie wpływają na ikonę */
  z-index: 10; /* Ustaw wyższy z-index, aby była nad tekstem */
}

.search-modal-results {
  display: flex;
  flex-direction: column;
  padding: 20px; /* Dodanie paddingu do góry i dołu */
  
}

.popular-shows {
  display: flex;
  flex-direction: column;
  padding: 20px; /* Dodanie paddingu do góry i dołu */
  
}

.search-result-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Dopasowanie marginesu dolnego */
  border: 0.5px solid #EAECF0;
  background: #fff;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.search-result-item .show-poster {
  position: relative;
  margin-right: 17px;
}

.search-result-item .poster-img {
  height: 130px; /* Wysokość plakatu zmniejszona do mniejszej wersji */
  width: auto;
  object-fit: cover;
  object-position: center;
  border-radius: 6px; /* Zaokrąglone rogi */
  aspect-ratio: 2 / 3; /* Maintains a 3:2 aspect ratio */
}

.search-modal-results .section-subtitle,
.popular-shows .section-subtitle {
  margin-top: 4px;
  margin-bottom: 10px;
}

.search-result-item .show-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.search-result-item .show-title {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.search-result-item .theater-info {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 2px;
}

.search-result-item .location-info {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 2px;
  opacity: 0.6;
}

.search-result-item .theater-info-add {
  color: #7F56D9;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 8px;
}

.search-result-item .label-unverified {
  font-size: 11px;
  margin-bottom: 10px;
  color: #DC6803;
}

.search-result-item .rating {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.search-result-item .rating-star {
  width: 16px;
  height: auto;
  margin-right: 5px;
}


.close-modal-button {
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color: #667085;
  margin-left: 16px;
}

/* Styl dla modala menu */
.menu-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #F9FAFB;
  z-index: 1000;
  overflow-y: auto;
  animation: fadeIn 0.25s ease forwards;
}

.menu-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
  animation: fadeIn 0.25s ease forwards;
}

.menu-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #fff;
  padding-top:21px;
  padding-bottom:22px;
  border-bottom: 0.5px solid #ccc;
}

.menu-logo {
    height: 40px; /* Dostosuj rozmiar logo do swoich potrzeb */
    width: auto;
    position:relative;
    top:2px;
    left:-2px;
    cursor: pointer;
}

.menu-modal-links {

  padding: 20px;
  width: 100%;


}

.menu-modal .user-links {
  margin-top: 14px;
  padding-top: 6px;
  border-top: 1px solid #E4E5E7;
}

.menu-modal .settings-links {
  margin-top: 14px;
  padding-top: 6px;
  border-top: 1px solid #E4E5E7;
  margin-bottom: 80px;
}

.menu-modal .quick-button {
  width: 100%;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


.language-choice-mobile {
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: right; /* Opcjonalnie dodaje równe odstępy między przyciskami */
  margin-top: 0px;
  margin-bottom: 0px;
  gap: 0.5rem;
}

.language-choice-mobile button {
  flex-grow: 1; /* Sprawia, że przyciski rozciągają się równomiernie */
  text-align: center; /* Wyśrodkowanie tekstu w przycisku */
  margin-top: 0px;
  margin-bottom: 0px;
}


.menu-modal .quick-icon {
  width: 22px;
  height: 22px;
  color: #344054;
}

.menu-modal .quick-icon-danger {
  filter: invert(16%) sepia(69%) saturate(4570%) hue-rotate(355deg) brightness(83%) contrast(89%);

}



.modal {
  background-color: rgba(52, 64, 84, 0.7); /* Półprzezroczysty kolor tła */
  backdrop-filter: blur(6px); /* Efekt rozmycia tła */
  -webkit-backdrop-filter: blur(6px); /* Prefiks dla Safari */
  pointer-events: auto; 

}




.modal-content {
  position: relative;
  z-index: 1000; /* Modal jest na wierzchu overlaya */
  border-radius: 12px;
  background-color: white;
  padding: 20px;
  width:94%;
  max-width: 500px;
  margin: 0 auto;
  top: 20px;
  margin-bottom: 30px;
}

.modal-body, .modal-footer {
  margin-bottom:0px;
  padding:0px;
}

.modal-section {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.modal-content .show-title {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 2px 0;
}

.modal-content .show-card {
  width: 150px; /* minimalna szerokość */
  position: relative;
  margin: 0 auto;
}

@media (max-width: 500px) {
  .modal-content .show-card {
    width: 120px !important; /* minimalna szerokość */
  }
}


.modal-content .show-card .show-poster {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden; /* Zapobiega wychodzeniu obrazu poza kontener */
}

.modal-content .show-card .show-poster::before {
  content: "";
  display: block;
  padding-top: 150%; /* Stosunek proporcji 3:2 */
}

.modal-content .show-card .show-poster img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Zachowuje proporcje obrazu */
  border-radius: 10px; /* Opcjonalnie, jeśli chcesz zaokrąglone rogi */
}

.modal-header {
  border: none;
  position:absolute;
  right:8px;
  top: 8px;
  z-index:1;
}

.modal-footer {
  border: none;
  display: flex;
  flex-direction: column;
  gap:12px;
}

.modal-footer button {
  width: 100%;
  margin:0
}


.modal-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.modal-content .theater-info {
  color: #475467;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-top: -12px;
  margin-bottom: 10px;
}

.modal-calendar {
  margin-bottom: 20px;
  margin-top: 40px;
}

.modal-subsection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 4px;
  width: 100%;
}

.modal-small-section {
  display: flex;
  flex-direction: column;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  align-items: stretch;
}

.modal-small-section-checkbox {
  display: flex;
  flex-direction: row;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  align-items: center;
  gap: 10px;
}

.modal-small-section-note {


  align-items: center;

}

.note-textarea:focus,
.note-textarea:active {
  border-color: #6941C6; /* Zmien kolor na wybrany */
  box-shadow: 0 0 5px rgba(105, 65, 198, 0.5);
  outline: none; /* Usuń domyślne obramowanie przeglądarki */
}

.modal-small-section-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #d0d5dd;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

.modal-small-section-checkbox input[type="checkbox"]:checked {
  background-color: #7F56D9;
  border-color: #7F56D9;
}

.modal-small-section-checkbox input[type="checkbox"]:checked::after {
  content: '✓';
  position: absolute;
  color: white;
  font-size: 14px;
  left: 2px;
  top: -3px;
}

.modal-small-section-checkbox .delete-wishlist {
  margin: 0;
  cursor: pointer;
  user-select: none;
  font-size: 13px;
}

.modal-small-section-bottom {
  text-align: center;
  display: flex;
  text-align:center;
  justify-content: space-evenly;
}

.modal-small-section-bottom button {
  width: 100%;
  margin-top: 16px;
}

.modal-small-section-top {
  display: flex;
  align-items: center;
  gap:6px;
}

.modal-small-section-top p {
  margin:0;
  padding:0;
}

.modal-small-section-top .sub {
  color: #667085;
}

.modal-small-section-top .icon-section {
  position: relative;
  top: -2px;
}

.modal-small-section-top h6 {
  margin-bottom:2px;
}

.modal-small-section-top .content {
  flex-grow: 1;
  margin-left: 10px;
}


.modal-poster .star-icon {
  width: 20px;
  height: 20px;
  margin-right: 2px;
}

.modal .star-rating {
  display: flex;
  gap: 9px;
  margin-top: 14px;
  margin-bottom: 4px;
}

@media (min-width: 3900px) {
  .modal .star-icon {
    width: 19px;
    font-size: 30px;
    width: 28px;
  }
}

.modal .star-icon {
  
  height: auto;
  
  width: 19px;
    font-size: 24px;
}

.modal-poster .gray-star {
  filter: grayscale(100%);
}



.modal-calendar-container .modal-content {
  width:auto;
  max-width: 500px;
  margin: 0 auto;
  top: 20px;
}

/* Styl dla komponentu DatePicker */

.custom-datepicker {
  text-align: center;
}

.custom-datepicker .react-datepicker__header,
.react-datepicker__current-month {
  text-align: center;
}

.custom-datepicker .react-datepicker__month {
  background: white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.custom-datepicker .react-datepicker__day--selected,
.custom-datepicker .react-datepicker__day--keyboard-selected {
    background-color: #6941C6; /* Kolor zaznaczonego dnia */
    color: white; /* Kolor tekstu zaznaczonego dnia */
    font-weight: 600;
    border-radius: 50px; /* Zwiększony border-radius */
}

.custom-datepicker .react-datepicker__day--selected:hover,
.custom-datepicker .react-datepicker__day--keyboard-selected:hover {
    background-color: #53389E !important; /* Kolor zaznaczonego dnia */
    color: white !important; /* Kolor tekstu zaznaczonego dnia */
    border-radius: 50px; /* Zwiększony border-radius */
}


/* Styl dla głównego kontenera kalendarza */
.custom-datepicker .react-datepicker {
    border: none; /* Usunięcie ramki */
    box-shadow: none; /* Usunięcie cienia */
    text-align: center; /* Wyśrodkowanie tekstu */
}

/* Styl dla dni tygodnia i dni kalendarza */
.custom-datepicker .react-datepicker__day,
.custom-datepicker .react-datepicker__day-name {
    font-size: 14px; /* Ustawienie rozmiaru tekstu */
    padding: 10px;
    padding-top: 14px;
    margin: 1px;
    width: 42px;
    height: 42px;
    line-height: 1;
    text-align: center;
    border-radius: 50px; /* Zwiększony border-radius */
}

.custom-datepicker .react-datepicker__header {
  background-color: #fff;
  border-bottom: 1px solid #D0D5DD;

}

.custom-datepicker .react-datepicker__current-month {
  margin-bottom: 16px;
}

.custom-datepicker .react-datepicker__navigation {
  top: 4px;
}

.custom-datepicker .react-datepicker__day-name {
  color: #344054;
  font-weight: 600;
}

.custom-datepicker .react-datepicker__day:hover {
    color: #6941C6;
    background-color: #F9F5FF; /* Kolor zaznaczonego dnia */
    border-radius: 50px; /* Zwiększony border-radius */
    font-weight: 600;

}

.custom-datepicker .react-datepicker__day-names {
  border-bottom: 0px solid #D0D5DD;
}

.custom-datepicker .react-datepicker__day--outside-month {
  color: #D0D5DD; /* Ustaw preferowany kolor dla dni spoza bieżącego miesiąca */
}



/* Usunięcie marginesu dla kalendarza */
.custom-datepicker .react-datepicker__month-container {
    margin: 0 auto; /* Wyśrodkowanie */
    min-height: 364px;
    background: #F9F5FF;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.cast-section {
  display: flex;
  flex-wrap: wrap; /* Pozwala na zawijanie elementów do kolejnych wierszy */
  gap: 30px; /* Przerwy między elementami */
  justify-content: space-around; /* Rozmieszczenie elementów równomiernie na szerokości */
  align-items: flex-start; /* Wyśrodkowanie elementów pionowo */
}

.cast-section a {
  color: #101828;
}

.cast-member {
  width: 120px;
  text-align: center;
  
}

.cast-photo {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #ffffff;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
}

.cast-member:hover .cast-photo {
  transform: translateY(-2px);
  box-shadow: 0 3px 10px rgba(105, 65, 198, 0.3);
  outline: none; /* Usuń domyślne obramowanie przeglądarki */
}

.cast-member:hover .cast-name {
  color: #6941C6;
}

@media (min-width: 390px) {
  .cast-member {
    width: 100px;
  }
  
  .cast-photo {
    width: 100px;
    height: 100px;
  }

  .cast-section {
 gap: 10px; /* Przerwy między elementami */
  }
  
}

.cast-name {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.2;
  word-wrap: break-word;
}

/* Styl dla sekcji wyszukiwania w AddToDiary */
.add-to-diary .search-section {
  margin-bottom: 20px;
}

.search-unverified-link {
  opacity: 0.5;
  font-size: 12px;
  cursor: pointer;
}

.search-unverified-link:hover {
  text-decoration: underline;
}


.add-to-diary .search-modal-results,
.add-to-diary .popular-shows {
  display: flex;
  flex-direction: column;
  padding: 0px;
}


.add-to-diary .search-result-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border: 0.5px solid #EAECF0;
  background: #fff;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.search-result-item .search-results-icon {
  margin-right: 12px;
  filter: invert(25%) sepia(49%) saturate(2513%) hue-rotate(240deg) brightness(101%) contrast(94%);
}

.search-result-item:hover .search-results-icon{
  filter: invert(31%) sepia(51%) saturate(5525%) hue-rotate(247deg) brightness(81%) contrast(89%);

}

.add-to-diary .search-result-item:hover,
.search-modal .search-result-item:hover {
  background: #F9FAFB;
}

.add-to-diary .search-result-item .show-poster {
  position: relative;
  margin-right: 17px;
}


.add-to-diary .search-result-item .poster-img {
  height: 130px;
  width: auto;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}

.add-to-diary .search-result-item .show-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.add-to-diary .search-result-item .show-title {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.add-to-diary .add-to-diary .search-result-item .theater-info {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
}

.add-to-diary .search-result-item .rating {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.add-to-diary .search-result-item .rating-star {
  width: 16px;
  height: auto;
  margin-right: 5px;
}

.add-to-diary .search-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.confirmation-message {
  margin: 20px;
}

.confirmation-message img {
  width: 70px;
}

.confirmation-message p {
  margin-top: 20px;
  margin-bottom: 30px;
}

.confirmation-message-modal .modal-content {
  width:auto;
  max-width: 500px;
  min-width: 300px;
  margin: 0 auto;
  top: 20px;
  text-align: center;
}

.confirmation-message-modal-rem {
  width:auto;
  max-width: 500px;
  min-width: 300px;
  margin: 0 auto;
  top: 20px;
  text-align: center;
}

.confirmation-message-modal-rem button {
  width:100%;
}

.bottom-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 -6px 6px rgba(43, 45, 103, 0.04);
  display: none;
  align-items: center;
  z-index: 1000;
  justify-content: space-evenly;
  height: 60px;
}


@media (max-width: 500px) {
  .bottom-menu {
    display: flex;
  }
}

@media (display-mode: standalone) {
  .bottom-menu {
    height: 94px;
    padding-bottom: 34px;
  }
}

/* Ukrywamy menu na większych ekranach */
@media (max-width: 500px) {


  .footer-container {
    margin-bottom: 60px;
  }
}


.menu-item {
  text-align: center;
  color: #2B2D67;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  font-weight: 700;
  transition: background-color 0.3s, color 0s;
  background-color: transparent; /* Brak tła */
  padding:8px;
  border-radius:5px;
  width: 70px;
}

.menu-icon {
  width: 22px;
  height: 22px;
  margin-bottom: 1px;
  filter: invert(72%) sepia(17%) saturate(280%) hue-rotate(179deg) brightness(89%) contrast(83%);
  background-color: transparent; /* Przezroczyste tło */
}

.menu-text {
  font-size: 12px;
  color: #8C9CB7;
  transition: color 0.3s;
}

/* Styl aktywnego elementu menu */
.menu-item.active .menu-icon {
  filter: invert(26%) sepia(90%) saturate(1675%) hue-rotate(243deg) brightness(83%) contrast(91%);


}

.menu-item.active .menu-text {
  color: #6941C6;
}

/* Efekt hover */
.menu-item:hover {
  background-color: #F9FAFB;
}

.menu-item:hover .menu-icon {
  filter: invert(45%) sepia(7%) saturate(400%) hue-rotate(175deg) brightness(88%) contrast(89%);
}

.menu-item:hover .menu-text {
  color: #475467;
}

/* Stylowanie etykiet formularza */
label {
  font-size: 12px;
  margin-bottom: 6px;
  margin-top: 13px;
  display: block;
  color: #667085;
}

/* Stylowanie pól formularza */
.form-control {
  font-size: 16px !important;
  padding: 10px 12px; /* Zwiększono padding */
}

/* Stylowanie tekstu błędu */
.text-danger {
  color: #dc3545;
  font-size: 12px;
  margin-top: 10px;
}

.add-custom-show button {
  width: 100%;
}

.add-custom-show-modal button {
  width:100%;
}

.add-custom-show-modal .form-control:focus {
  border-color: #6941C6; /* Zmien kolor na wybrany */
  box-shadow: 0 0 5px rgba(105, 65, 198, 0.5);
  outline: none; /* Usuń domyślne obramowanie przeglądarki */
}

.dropdown-menu {
  position: absolute;
  z-index: 1050; /* Wyższy z-index, aby być nad innymi elementami */
}


.dropdown-item {
  padding: 18px 20px 14px 20px;
}

.no-shows-av {
  height: 100%;
  min-height:300px;
}

.filter-container {
  display: flex;
    gap: 8px;
}

.language-choice {
  font-size: 18px;
  margin-top:-2px;
  margin-left: 3px;
}

@media (max-width: 768px) {
  .language-choice {
    display: none;
  }
}

.modal-dialog-bottom {
  position: fixed;
  bottom: 0;
  margin: 0;
  right:0;
  width: 100%;

}

.no-blur-modal {
  background-color: rgba(255, 255, 255, 0.0);  /* Zamiast ciemnego tła, jasne tło */
  backdrop-filter: none;  /* Usuwa efekt blur */
  -webkit-backdrop-filter: none;
  pointer-events: none; /* Wyłącza interakcje dla całego modala */

}

.no-blur-modal .modal-dialog {
  pointer-events: auto; /* Włącza interakcje dla samego dialogu (treści modala) */
}

.custom-backdrop {
  display: none; /* Ukrywa backdrop, jeśli jakikolwiek nadal istnieje */
}


.contact-info {
  text-align: center;
    font-size: 24px;
  margin-top: -10px;
}

.about-img {
  width: 100%;
  margin-bottom: 20px;
}

.about-page .title-col h1,
.about-page .row-contact h2 {
    background: linear-gradient(45deg, #E9D7FE, #B692F6, #F79009, #FDB022);
    background-size: 300% 300%;
    animation: gradient 10s ease infinite;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: bold;
    width: 100%;
    font-size: 36px !important;
    margin-top: 5px;
    margin-bottom: 30px !important;
    text-align: center;
}

.about-page .row-contact {
  padding-top: 30px !important;
}

.about-page .row-about {
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
}

@media (max-width: 768px) {
  .about-page .row-about {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: -24px;
  }

  .about-page .row-about .left {
    width: 100% !important;
    max-height: 210px;
  }

  .about-page .row-about .welcome-modal-image {
  width: -webkit-fill-available;
  max-height: 280px;
  margin-bottom: -190px !important;
}
  
  .about-page .row-about .right {
    width: 100% !important;
  }

  .about-page .row-numbers {
    flex-direction: column !important;
    gap: 14px !important;
  }

  .about-page .row-numbers .number-container {
    padding-top: 30px !important;
    padding-bottom: 40px !important;
}
.about-page .row-numbers .number {
  font-size: 34px !important;
}
.about-page .row-features {
  flex-direction: column !important;
  gap: 13px !important;
  
}
.about-page .row-features .feature-image {

  max-width: 240px !important;
}

.about-page .row-features .feature-image-container {
  max-height: 200px !important;
}
.about-page .about-section-title {
  text-align: center;
  font-size: 24px !important;
}
.about-page h1 {
  padding-left: 10px;
  padding-right: 10px;
}
.about-page p {
  text-align: center;
  font-size: 13px;
}
.about-page .row-theaters {
  padding-top: 30px !important;
  padding-bottom: 18px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
}


.about-page .row-about .left {
  width: 50%;
  overflow: hidden;
  background: #F4B934;
  padding-top: 30px;
  border-radius: 20px;
  display: flex;
  align-items: end;
}

.about-page .row-about .welcome-modal-image {
  width: -webkit-fill-available;
  max-height: 400px;
  margin-bottom: -70px;
}


.about-page .row-about .right {
  width: 50%;
  text-align: left;
}

.about-page .about-section-header {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.about-page .about-section-title {
  margin-bottom: 34px;
  font-size: 28px;
}

.about-page .about-section-subtitle {
  max-width: 750px;
}

.about-page p {
  opacity: 0.8;
}

.about-page .row-numbers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  gap: 26px
}

.about-page .row-numbers .number-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: center;
  background: #F9F5FF;
  border-radius: 20px;
  padding-top: 40px;
  padding-bottom: 50px;
  width: 100%;
}

.about-page .row-numbers .number {
  font-size: 50px;
  color: #7F56D9;
  font-weight: 700;
}

.about-page .row-numbers .description {
  color: #53389E;
  opacity: 0.8;
}

.about-page .row-theaters {
  background: white;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 38px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 20px;
  align-items: center;
}

.about-page .row-theaters .theaters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.about-page .row-theaters .about-section-title {
  text-align: center;
}

.about-page .row-theaters .theater-logo {
  margin: 14px;
  filter: saturate(0%);
  opacity: 0.7;
}

.l-roma {
  height: 32px;
}

.l-gdynia {
  height: 28px;
}

.l-lodz {
  height: 56px;
}

.l-syrena {
  height: 26px;
}

.l-poznan {
  height: 50px;
}

.l-rozrywki {
  height: 50px;
}

.l-capitol {
  height: 64px;
}

.about-page .row-features {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-evenly;
  width: 100%;
  gap: 26px;
  
}

.about-page .row-features .feature-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  background: #F9F5FF;
  border-radius: 20px;

  padding-bottom: 30px;
  width: 100%;
  
}

.about-page .row-features .feature {
  font-size: 16px;
  color: #42307D;
  font-weight: 700;
  padding-left: 14px;
  padding-right: 14px;
}

.about-page .row-features .feature-image-container {
  max-height: 260px;
  overflow: hidden;
  margin-bottom: 20px;
  background: linear-gradient(45deg, #7F56D9, #53389E);
  padding-top: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.about-page .row-features .feature-image {

  max-width: 80%;
}

.about-page .row-features .description {
  color: #42307D;
  opacity: 0.8;
  font-size: 13px;
  padding-left: 14px;
  padding-right: 14px;
}


.page-title-container .page-subtitle {
  color: white;
  margin-top: 20px;
}

.my-account .rank-item {
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;
  width: 100%;
}

.my-account .show-poster {
  position: relative;
}

.my-account .poster-img {
  height: 80px; /* Wysokość plakatu zmniejszona do mniejszej wersji */
  width: auto;
  object-fit: cover;
  object-position: center;
  border-radius: 6px; /* Zaokrąglone rogi */
  aspect-ratio: 2 / 3; /* Maintains a 3:2 aspect ratio */
}

.my-account .rank-buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 10px;
}

.my-account .account-intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Stylowanie etykiet formularza */
.my-account label {
  font-size: 14px;
  margin-bottom: 6px;
  margin-top: 0px;
  display: block;
  color: #212529;
}

/* Stylowanie pól formularza */
.my-account .form-control {
  font-size: 16px !important;
  padding: 10px 12px; /* Zwiększono padding */
}

.my-account .form-check {
  padding-left: 0;
  margin-bottom: 12px;
}

.my-account .form-switch {
  margin-bottom: 0px;
}

.my-account .form-switch {
  padding-left: 1em;
}

.my-account .form-check-label {
  margin-top: 0px;
}

.my-account .form-check-input {
  height: 1.5em;
  margin-top: 0;
  margin-right: 12px;

}

.form-check-input:checked {
  background-color: #7F56D9;
  border-color: #7F56D9;

}

.form-check-input:focus,
.form-check-input:active {
  box-shadow: 0 0 0 4px rgba(127, 86, 217, 0.25); /* Delikatny fioletowy cień */
  outline: none; /* Usuwa niebieski obrys */
}

.my-account .form-check-input[type=radio] {
  width: 1.5em;
}

.my-account .form-switch .form-check-input {
  width: 2.5em;
}

.my-account .info-content .text-muted {
  margin-left: 13px;
  margin-top: -12px;
  padding-top: 0px;
  font-size: 12px;
  opacity: 0.6;
}


.my-account .info-name {
  padding-right: 30px;
}

.my-account .users-list {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: auto;
}

.my-account .users-list-main {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.my-account .user-link {
  display: block;
  width: 100%; /* Sprawia, że link pokrywa całą szerokość */
  text-decoration: none; /* Usuń podkreślenie linku */
}

.my-account .users-list-left {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 10px;
  flex: 1;  /* Pozwól, aby ten element wypełnił pozostałą przestrzeń */
  border-radius: 4px;
  width: 100%;
  flex-grow: 1;
  align-items: center;
}

.my-account .users-list-left a,
.my-account .users-list-left p {
  color: #212529;
}

.my-account .users-list-left:hover {
  background: #F9FAFB;
}

.my-account .div-unfollow {
  min-width: 140px;
  margin-right: 10px;
}

.my-account .users-list .unfollow {
  width: auto;
}

.my-account .users-list .user-avatar {
  width: 42px;
  height: 42px;
}
.my-account .users-list .user-name {
  margin: 0;
  padding: 0;
  font-size: 15px;
}

.my-account .card {
  margin-bottom: 6px; /* Dopasowanie marginesu dolnego */
  border: 0.5px solid #EAECF0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.my-account .users-row .card-body {
  padding: 6px;
}

.my-account .btn {
  width: 100%;
}





.stats-rank .following-followers {
  display: flex;
  flex-direction: row;
  gap: 0px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid var(--Gray-300, #DFE2E6);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 6px;
}

.following-followers .f-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  min-width: 140px;
  cursor: pointer;
  border-radius: 4px;
  padding: 10px;
}

.following-followers .f-left {
  border-right: 1px solid var(--Gray-300, #DFE2E6);
  margin-right: 6px;
  padding-right: 6px;
}

.following-followers .f-box:hover {
  background: #F9F5FF;
}

.following-followers .f-box:hover .f-label {
  color: #6941C6;
}

.following-followers .f-label {
  font-size: 13px;
  color: #667085;
}

.following-followers .f-number {
  font-size: 18px;
  font-weight: 700;
  color: #6941C6;
}

.modal-followers {
  position: relative;
  top: -10px;
}

.modal-followers .users-list {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: auto;
}

.modal-followers .users-list-main {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-width: 380px;
}

@media (max-width: 600px) {
  .modal-followers .users-list-main {
    gap: 8px;
    width: 100%;
    min-width: 0px;
  }
}

.modal-followers .user-link {
  display: block;
  width: 100%; /* Sprawia, że link pokrywa całą szerokość */
  text-decoration: none; /* Usuń podkreślenie linku */
}

.modal-followers .users-list-left {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 10px;
  flex: 1;  /* Pozwól, aby ten element wypełnił pozostałą przestrzeń */
  border-radius: 4px;
  width: 100%;
  flex-grow: 1;
  align-items: center;
}

.modal-followers .users-list-left a,
.modal-followers .users-list-left p {
  color: #212529;
}

.modal-followers .users-list-left:hover {
  background: #F9FAFB;
}

.modal-awards-container .users-list-left:hover {
  background: #Ffffff !important;
}

.modal-followers .div-unfollow {
  min-width: 120px;
  margin-right: 0px;
  display: flex;
}

.modal-followers .users-list .unfollow {
  width: auto;
}

.modal-followers .users-list .user-avatar {
  width: 42px;
  height: 42px;
}
.modal-followers .users-list .user-name {
  margin: 0;
  padding: 0;
  font-size: 15px;
  padding-right: 20px;
}

.modal-followers .card {
  margin-bottom: 6px; /* Dopasowanie marginesu dolnego */
  border: 0.5px solid #EAECF0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.modal-followers .card-body {
  padding: 6px;
}

.modal-followers .users-row .card-body {
  padding: 6px;
}

.stats-rank .show-rating {
  font-size: 14px;
  margin-bottom: 1px;
  line-height: 14px;
  font-weight: 600;
}

.stats-rank .show-rating-sub {
  font-size: 11px;
  margin-bottom: 8px;
  color: #667085;
}

.stats-rank .rating-star {
  width: 16px;
  height: auto;
  margin-right: 2px;
  margin-top: -3px;
}

.stats-rank .seen-eye {
  width: 13px;
  height: auto;
  margin-right: 6px;
  margin-top: -1px;
}

.stats-rank .priv-rank-container {
  padding: 16px !important;
}


.stats-rank .priv-rank-container  .card-body {
  padding: 10px;
}

.stats-rank .priv-rank-container .rank-number {
  font-weight: 600;
  text-align: center;
  flex-shrink: 0;
  width: 40px; /* Zapewnia minimalną szerokość dla numeru rankingu */
  background-color: #F9F5FF;
  height: 75px;
  align-self: center;
  color: #6941C6;
  border-radius: 4px;
  padding: 10px;
  display: flex; /* Dodaj flexbox */
  align-items: center; /* Wyrównaj zawartość pionowo */
  justify-content: center; /* Wyrównaj zawartość poziomo */
}

.rank-title-theater {
  word-wrap: break-word; /* Zawijanie tekstu, jeśli jest zbyt długi */
  white-space: normal; /* Pozwala na zawijanie tekstu */
}

.rank-item {
  display: flex;
  align-items: center;
  width: 100%;
}


.stats-rank .priv-rank-container .show-poster {
  flex-shrink: 0;
  margin-right: 4px;
  width: auto;
  margin-bottom: 0px;
}

.stats-rank .priv-rank-container .poster-img {
  width: 50px; /* Ustalona szerokość plakatu */
  height: auto;
}

.rank-title-theater {
  flex-grow: 1;
}

.text-wrap {
  word-wrap: break-word;
  white-space: normal;
}

.stats-rank .priv-rank-container  .show-title {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
}

.stats-rank .priv-rank-container  .theater-info {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}

.stats-rank .priv-rank-container .location {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
}
.drag-icon {
  filter: grayscale(100%) brightness(70%);
}

.drag-icon-container {
  height: 75px;
  padding: 10px;
  padding-left: 20px;
  display: flex; /* Dodaj flexbox */
  align-items: center; /* Wyrównaj zawartość pionowo */
  justify-content: center; /* Wyrównaj zawartość poziomo */

}



.grey {
  filter: grayscale(100%) brightness(100%);
  opacity: 0.7;
}

.white {
  filter: brightness(0) invert(1);
  fill: white;
}

.custom-toast {
  background-color: #ECFDF3; /* Ciemny kolor */
  color: #027A48;              /* Biały tekst */
  border: 1px solid #A6F4C5;
  border-radius: 10px;        /* Zaokrąglone rogi */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.03); /* Cień dla efektu unoszenia */
  padding: 0.75rem 1.25rem;  /* Odstępy wewnętrzne */
  position: absolute;
  top: 140px;
  right: 50px;
  width: auto;
  z-index: 1050
}

@media (max-width: 500px) {
  .custom-toast {
    margin-top: 5px;

   }
}

@media (min-width: 870px) {

.stats-rank .my-shows-buttons > * {
  flex: 0; /* Zapewnia, że elementy nie rozciągają się na całą dostępną przestrzeń */
  white-space: nowrap; /* Zabezpieczenie przed zawijaniem tekstu */
}

.stats-rank .my-shows-buttons {
  display: flex;
  justify-content: flex-start; /* Elementy będą ustawione obok siebie */
  align-items: center; /* Dostosowuje pozycjonowanie elementów w osi Y */
  gap: 16px; /* Odstępy między elementami */
}

}

.generate-rank {
  min-height: 60px;
}

.social-template {
  width: 1080px;
  height: 1920px;
  position: relative;
}


.social-template .box {
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.1);
}

.social-template .intro {
  position: absolute;
  top: 120px;
  left: 64px;
}

.social-template .intro h1 {
  font-size: 64px;
  color: #fff;
}

.social-template .intro h2 {
  font-size: 40px;
  color: #fff;
  font-weight: 400;
  margin-top: 7px;
}

.social-template .avatar {
  position: absolute;
  top: 163px;
  right: 163px;
}

.social-template .avatar .user-avatar {
  width: 96px;
  height: 96px;
}

.social-template .shows-container {
  position: absolute;
  top: 320px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding-left: 64px;
  padding-right: 44px;
}

.social-template .shows-container h3 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 22px;
  text-align: center;
  opacity: 0.9;
}

.social-template .shows-container .show-info {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  opacity: 0.8;
  margin-bottom: 10px;
}

.social-template .seen-eye {
  width: 20px;
  height: auto;
  margin-right: 8px;
  margin-top: -3px;
}

.social-template .rating-star {
  width: 20px;
  height: auto;
  margin-right: 8px;
  margin-top: -6px;
}

.social-template .show-card {
  max-width: 172px !important;
}


.social-template .total-entries {
  position: absolute;
  bottom: 220px;
  text-align: center;
  width: 100%;
  color: white;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

.social-template .total-entries .entries {
  color: #F4B02E;
}

.social-template .total-entries .number {
  font-size: 48px;
}

ol li {
  font-size: 15px;
  margin-bottom: 8px;
}

.hidden {
  position: absolute;
  left: -10000px; /* Przesunięcie kontenera daleko poza ekran */
}

.show-more-entries {
  margin: 0 20px;
}

.hero-container {
  position: relative;
  min-height: 50vh;
  padding: 4rem 0;
  overflow: hidden;
}


@keyframes groupAnimation {
  0% {
    opacity: 0;
    transform: scale(0.3) rotate(-15deg);
  }
  /* Szybkie pojawienie się */
  10% {
    opacity: 1;
    transform: scale(1.2) rotate(5deg);
  }
  15% {
    transform: scale(1) rotate(0deg);
  }
  /* Pozostanie w miejscu */
  70% {
    opacity: 1;
    transform: scale(1) rotate(0deg);
  }
  /* Grupowe zniknięcie */
  100% {
    opacity: 0;
    transform: scale(0.3) rotate(15deg);
  }
}

.discourse-topics .topic-avatar img {
  width: 45px;
  height: 45px;
  object-fit: cover;
}


.discourse-section .card {
  margin-bottom: 10px; /* Dopasowanie marginesu dolnego */
  border: 0.5px solid #EAECF0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}




.discourse-section .card-body {
  padding: 20px;
  justify-content: center;
}



.discourse-section .rank-number {
  font-weight: 600;
  text-align: center;
  flex-shrink: 0;
  width: 40px; /* Zapewnia minimalną szerokość dla numeru rankingu */
  background-color: #F9F5FF;
  height: 75px;
  align-self: center;
  color: #6941C6;
  border-radius: 4px;
  padding: 10px;
  display: flex; /* Dodaj flexbox */
  align-items: center; /* Wyrównaj zawartość pionowo */
  justify-content: center; /* Wyrównaj zawartość poziomo */
}

.discourse-section .rank-title-theater {
  word-wrap: break-word; /* Zawijanie tekstu, jeśli jest zbyt długi */
  white-space: normal; /* Pozwala na zawijanie tekstu */
}

.discourse-section .rank-item {
  display: flex;
  align-items: start;
  width: 100%;
}


.discourse-section .show-poster {
  flex-shrink: 0;
  margin-right: 4px;
  width: auto;
  margin-bottom: 0px;
}

.discourse-section.poster-img {
  width: 50px; /* Ustalona szerokość plakatu */
  height: auto;
}

.discourse-section .rank-title-theater {
  flex-grow: 1;
}

.discourse-section .text-wrap {
  word-wrap: break-word;
  white-space: normal;
}

.discourse-section .topic-title {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 3px;
  line-height: 20px;
}

.discourse-section .topic-title:hover {
  text-decoration: underline;
}

.discourse-section .topic-excerpt {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  margin-top: 6px;
}

.discourse-section .topic-meta {
  line-height: 19px;
  margin-bottom: 5px;
}

.discourse-section .author-info {
  color: #667085;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;

}

.discourse-section .author-info a {
  color: #6941C6;
}

.discourse-section .author-info a:hover {
  text-decoration: underline;
}

.discourse-section p {
  margin: 0;
}

.discourse-section .topic-replies {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  margin-top: 6px;
}

.discourse-section .location {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
}

.topic-header {
  display: flex;
  gap: 16px;
  align-items: center;
}

.show-mini-poster {
  width: 50px;
  height: 75px;
  object-fit: cover;
  border-radius: 4px;
}

.topic-content {
  flex: 1;
}

.discourse-latest .card {
  margin: 0;
}

.discourse-latest .card-body {
  padding: 10px;

}



.discourse-latest .tags {
  margin-top: 8px;
}

.discourse-latest .topics-container {
  margin: 0;
}

.discourse-latest .topic-link {
  width: 100%;
  padding: 5px;
  text-decoration: none;
  margin: 0;
}


@media (min-width: 700px) {
  .discourse-latest .topic-link {
    width: 50%;
  }
}



/* Kontener dla obu sekcji */
.discourse-sections-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
}

/* Modyfikacja istniejących klas dla sekcji */
.discourse-section.discourse-latest {
  flex: 1;
  min-width: 300px; /* Minimalna szerokość dla czytelności */
}

/* Media query dla mniejszych ekranów */
@media (max-width: 699px) {
  .discourse-sections-wrapper {
    flex-direction: column;
    gap: 40px;
  }

  .discourse-section.discourse-latest {
    width: 100%;
  }
}

.discourse-latest .topics-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Dodaj stały odstęp 10px między kartami */
}

.discourse-latest .topic-link {
  width: 100%;
  padding: 0; /* Usuń padding */
  text-decoration: none;
  margin: 0; /* Usuń margin */
}

.discourse-latest .card {
  margin: 0; /* Usuń margin */
  height: 100%; /* Zapewnij pełną wysokość */
}

.forum-in-title {
  height: 28px;
  margin-top: -5px;
  margin-right: 5px;
}

/* Stylowanie Activities */

/* Kontener dla obu sekcji */
.personal-sections-wrapper {
  display: flex;

  gap: 24px;
  width: 100%;
}

@media (min-width: 700px) {
  .personal-sections-wrapper {
    flex-direction: wrap;
  }
}

.hp-user-section {
  background-color: #F8F9FC;
  position: relative;
  top: -24px;
  padding-right: 16px;
  padding-left: 16px;
  border-bottom: 1px solid white;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (max-width: 768px) {
  .hp-user-section {
    top: -10px;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    border-top: 1px solid white;
  }
}

.my-and-activity {
  display: flex;
  width: 100%;
  gap: 48px;
  min-height: 0; /* Dodane */
}

.my-shows .add-show-button {
  margin: 0 auto 0;
}


.my-and-activity .personal-activities {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0; /* Dodane */
}

.my-and-activity .activity-column-container {
  justify-content: space-between;
  display: flex;
  position: relative;
  height: 440px; /* Stała wysokość kontenera */
  overflow: hidden;
  flex: 1 1 auto;
  flex-direction: column;
}


  .my-and-activity .topics-container {
    justify-content: start;
    display: flex;
    height: 100%;
    position: relative;
    padding-bottom: 0px; /* Miejsce na przycisk "pokaż więcej" */
  }
  .my-and-activity .topics-container::after {
    content: '';
    position: absolute;
    bottom: 0px; /* Ta sama wartość co padding-bottom */
    left: 0;
    right: 0;
    height: 180px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      #F8F9FC 50%,
      #F8F9FC 100%
    );
    pointer-events: none; /* Pozwala na klikanie elementów pod gradientem */
  }

  .my-and-activity .show-more-button {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1; /* Upewniamy się, że przycisk jest nad gradientem */
  }

  .my-and-activity .topics-container .card {
    flex: 0
  }

  .my-and-activity .topics-container .card-body {
    flex-grow: 0
  }

  .my-and-activity .topics-container .btn {
    flex: 0 0 auto;
  }

  .my-and-activity .see-rank-button {
    flex: 0 0 auto;
  }

@media (min-width: 850px) {
  .my-and-activity .my-shows {
    flex: 2 1 auto;
    width: 66.66%;
    min-height: 0;
    flex-direction: column;
    display:flex;
}

.my-and-activity .section-title,
.show-tabs {
  flex-shrink: 0;
}

.my-and-activity .shows-grid-container {
  display: flex
;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex-grow: 1;
}

.my-and-activity .personal-activities {
  width: 33.33%;
}
}

.my-and-activity .card-body {
  align-items: center;
  display: flex;
}

.my-and-activity .personal-activities .show-tabs-container {
  flex-shrink: 0;
}

.user-activity-container {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 10px;
}

.activity-user img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.single-activity-container {
  border-left: 1px solid #EAECF0;
  padding-left: 10px;
  font-size: 13px;
  color: #101828;
}

.single-activity-container  .activity-username {
  font-weight: 700;
}

.single-activity .activity-rating {
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.single-activity .number-rating {
  font-size: 16px;
  font-weight: 700;
}

.single-activity .star-icon {
  width: 20px;
  height: auto;
  font-size: 20px;
  margin-top: -5px;
}

.my-and-activity .single-activity .star-icon {
  width: 19px;
  height: auto;
  font-size: 20px;
  margin-top: -5px;
}

.single-activity .show-info img {
  width: 11px;
  position: relative;
  top:-1px;
  margin-right: 5px;
}


.personal-section .card {
  margin-bottom: 10px; /* Dopasowanie marginesu dolnego */
  border: 0.5px solid #EAECF0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.personal-section .card-body {
  padding: 20px;
}


.personal-section .rank-title-theater {
  word-wrap: break-word; /* Zawijanie tekstu, jeśli jest zbyt długi */
  white-space: normal; /* Pozwala na zawijanie tekstu */
}

.personal-section .rank-item {
  display: flex;
  align-items: start;
  width: 100%;
}


.personal-section .show-poster {
  flex-shrink: 0;
  margin-right: 4px;
  width: auto;
  margin-bottom: 0px;
}

.personal-section.poster-img {
  width: 50px; /* Ustalona szerokość plakatu */
  height: auto;
}

.personal-section .rank-title-theater {
  flex-grow: 1;
}

.personal-section .text-wrap {
  word-wrap: break-word;
  white-space: normal;
}

.personal-section .show-title {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
}

.personal-section .show-info {
  color: #667085;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
}

.personal-section p {
  margin: 0;
}

.personal-section .topic-replies {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  margin-top: 6px;
}

.personal-section .location {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
}

.personal-section .single-activity {
  display: flex;
  gap: 0px;
  align-items: start;
  flex-direction: column;
}

.personal-section .single-activity .activity-show-info {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-direction: row;
}

.single-activity .show-mini-poster {
  width: 40px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}

.personal-section .activity-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.personal-activities .card {
  margin: 0;
}

.personal-activities .card-body {
  padding: 10px;

}

.personal-activities .tags {
  margin-top: 8px;
}

.personal-activities .topics-container {
  margin: 0;
}

.personal-activities .topic-link {
  width: 100%;
  padding: 5px;
  text-decoration: none;
  margin: 0;
}

.single-activity-container a {
  color: #000000;
  text-decoration: none
}

.single-activity-container a:hover {
  text-decoration: underline;
}


@media (min-width: 700px) {
  .personal-activities .topic-link {
    width: 50%;
  }
}


/* Modyfikacja istniejących klas dla sekcji */
.personal-section.personal-activities {
  flex: 1;
  min-width: 400px; /* Minimalna szerokość dla czytelności */
}

@media (max-width: 1900px) {
  .personal-section.personal-activities {
    min-width: 300px; /* Minimalna szerokość dla czytelności */
  }
}

/* Media query dla mniejszych ekranów */

@media (max-width: 850px) {
.my-and-activity.personal-sections-wrapper {
  flex-direction: column;
  gap: 40px;
}
}

@media (max-width: 699px) {
  .personal-sections-wrapper {
    flex-direction: column;
    gap: 40px;
  }
  


  .personal-section.personal-activities {
    width: 100%;
  }
}

.personal-activities .topics-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Dodaj stały odstęp 10px między kartami */
}

.homepage .personal-activities .topics-container .activities-empty-wrapper {
  height: 100%;
  margin: 0;
  display: flex;
  flex: 1 1;
  align-items: center;
  margin-top: -60px;
}

.personal-activities .topic-link {
  width: 100%;
  padding: 0; /* Usuń padding */
  text-decoration: none;
  margin: 0; /* Usuń margin */
}

.personal-activities .card {
  margin: 0; /* Usuń margin */
  height: 100%; /* Zapewnij pełną wysokość */
}

.forum-in-title {
  height: 28px;
  margin-top: -5px;
  margin-right: 5px;
}

/* Koniec activities */

.login-security {
  max-width: 450px;
  border: 1px solid #D7EDE0;
  padding: 12px;
  border-radius: 8px;
  background: #F6FEF9;
  text-align: left;
  font-size: 12px;
}



.login-security-1 {
  color: #027A48;
  display: flex;
  align-items: center;
}

.login-security-2 {
  color: #027A48;
  opacity: 0.7;
  display: flex;
  align-items: center;
}

.login-security img {
  width:18px;
  margin-right: 12px;
  position: relative;
  top: -1px;
}

.login-security-1 img {
  filter: invert(36%) sepia(89%) saturate(1009%) hue-rotate(122deg) brightness(93%) contrast(98%);}

.login-security-2 img {
  filter: invert(32%) sepia(91%) saturate(577%) hue-rotate(109deg) brightness(90%) contrast(98%);  opacity: 0.7
}

.security-more {
  cursor: pointer;
  text-decoration: underline;
}

.recent-activities {
  margin-top: 2rem;
}

.activities-container {
  margin-top: 1rem;
}

.activity-card {
  background: white;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.activity-user {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}



.activity-content {
  margin: 0.5rem 0;
}

.activity-show {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}

.activity-poster {
  width: 48px;
  height: 72px;
  border-radius: 6px;
  margin-right: 1rem;
  object-fit: cover;
}

.activity-show-info {
  flex: 1;
}

.activity-show-title {
  margin: 0;
  font-size: 0.9rem;
}

.activity-rating {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
}

.activity-star-icon {
  width: 16px;
  height: 16px;
  margin-right: 0.25rem;
}

.activity-timestamp {
  font-size: 0.8rem;
  color: #666;
  text-align: right;
}

.activity-show-link {
  text-decoration: none;
  color: inherit;
}

.activity-show-link:hover {
  text-decoration: none;
  opacity: 0.9;
}

/* Modyfikacja istniejących klas */
.discourse-sections-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
}

/* Modyfikacja sekcji dla większych ekranów */
@media (min-width: 700px) {
  .discourse-sections-wrapper {
    align-items: stretch; /* Rozciąga elementy do tej samej wysokości */
  }

  .discourse-section.discourse-latest {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .discourse-section.discourse-latest .topics-container {
    flex: 1; /* Wypełnia dostępną przestrzeń */
    display: flex;
    flex-direction: column;
    gap: 10px; /* Zachowuje stały odstęp między elementami */
  }

  /* Kontener dla kart (bez przycisku) */
  .discourse-section.discourse-latest .topics-container > :not(.see-rank-button) {
    flex: 1; /* Tylko karty się rozciągają */
    display: flex;
  }

  .discourse-section.discourse-latest .topic-card {
    flex: 1; /* Karty rozciągają się na pełną wysokość */
    display: flex;
    flex-direction: column;
  }

  .discourse-section.discourse-latest .card-body {
    flex: 1; /* Treść karty rozciąga się na pełną dostępną wysokość */
    display: flex;
    flex-direction: column;
  }

  /* Przycisk zachowuje swoją oryginalną wysokość */
  .discourse-section.discourse-latest .see-rank-button {
    flex: 0 0 auto;
  }
}

/* Dla mniejszych ekranów - zachowanie domyślnej wysokości */
@media (max-width: 699px) {
  .discourse-section.discourse-latest {
    height: auto;
  }

  .discourse-section.discourse-latest .topic-card {
    height: auto;
  }
}

.calendar-main .calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, minmax(50px, 1fr));
  gap: 1px;
  background-color: #eee;
  border: 1px solid #ddd;
  margin-top: 20px;
}

.calendar-main .calendar-header {
  background: #F9FAFB;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}


.calendar-main .calendar-day {
  background-color: white;
  min-height: 100px;
  padding: 5px;
  position: relative;
  cursor: pointer;
}

.calendar-main .calendar-day:hover {
  box-shadow: inset 0 3px 0px #E9D7FE;
}

.calendar-main .calendar-day.empty {
  background-color: #f8f9fa;
  cursor: default;
}

.calendar-main .calendar-day.empty:hover {
  box-shadow: inset 0 0 0 white;
}

.calendar-main .day-number {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #666;
}

.calendar-main .day-events {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.calendar-main .event-link,
.weekly-calendar .event-link {
  font-size: 0.8em;
  border-radius: 3px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 7px;
  border-radius: 4px;
}

.has-multiple {
  padding-right: 20px !important;
}

.calendar-main .event-link:hover,
.weekly-calendar .event-link:hover {
  filter: brightness(98%); /* Przyciemnienie elementu o 50% */
}


/* Dodaj więcej stylów dla innych miast */

@media (max-width: 768px) {

  .calendar-main  .overlay-shadow,
  .weekly-calendar .overlay-shadow {
    position: relative;
    overflow: hidden;
  }

  .calendar-main  .overlay-shadow::before,
  .weekly-calendar .overlay-shadow:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    box-shadow: inset -8px 0 4px var(--city-bg);
  }

  .has-multiple.overlay-shadow::before {
    box-shadow: inset -16px 0 4px var(--city-bg) !important;
  }

  /* Kolory dla miast */
  .overlay-shadow.warszawa { --city-bg: #F4F3FF; }
  .overlay-shadow.kraków { --city-bg: #F0F9FF; }
  .overlay-shadow.gdynia { --city-bg: #FFF1F3; }
  .overlay-shadow.gdańsk { --city-bg: #FFF1F3; }
  .overlay-shadow.łódź { --city-bg: #ECFDF3; }
  .overlay-shadow.poznań { --city-bg: #FFF6ED; }
  .overlay-shadow.bydgoszcz { --city-bg: #FFFAEB; }
  .overlay-shadow.toruń { --city-bg: #FDF2FA; }
  .overlay-shadow.wrocław { --city-bg: #EEF4FF; }
  .overlay-shadow.chorzów { --city-bg: #F8F9FC; }
  .overlay-shadow.szczecin { --city-bg: #FFF1F3; }
  .overlay-shadow.koszalin { --city-bg: #F9F5FF; }
  .overlay-shadow.białystok { --city-bg: #EFF8FF; }

}

/* Style dla różnych miast */
.event-link.warszawa { color: #5925DC; background: #F4F3FF; }
.event-link.kraków { color: #026AA2; background: #F0F9FF; }
.event-link.gdynia { color: #C01048; background: #FFF1F3; }
.event-link.gdańsk { color: #C01048; background: #FFF1F3; }
.event-link.łódź { color: #027A48; background: #ECFDF3; }
.event-link.poznań { color: #C4320A; background: #FFF6ED; }
.event-link.bydgoszcz { color: #B54708; background: #FFFAEB; }
.event-link.toruń { color: #C11574; background: #FDF2FA; }
.event-link.wrocław { color: #3538CD; background: #EEF4FF; }
.event-link.chorzów { color: #363F72; background: #F8F9FC; }
.event-link.szczecin { color: #C01048; background: #FFF1F3; }
.event-link.koszalin { color: #6941C6; background: #F9F5FF; }
.event-link.białystok { color: #175CD3; background: #EFF8FF; }

.calendar-main .calendar-container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.calendar-main .calendar-navigation,
.calendar-day-view .day-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
}

.calendar-main .calendar-navigation .nav-arrow,
.calendar-day-view .day-navigation .nav-arrow{
  cursor: pointer;
}

.calendar-main .calendar-navigation .nav-arrow:hover,
.calendar-day-view .day-navigation .nav-arrow:hover{
filter: grayscale(100%) brightness(96%);
transition: filter 0.3s ease;
}

.calendar-main .update-info {
  color: #666;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .calendar-main .calendar-grid {
    grid-template-columns: repeat(7, minmax(50px, 1fr));
    gap: 0px;
    background-color: transparent;
    border: 0px solid #ddd;
  }
  
  .calendar-main .calendar-day {
  padding: 1px;
}
  
.calendar-main .day-events {
    margin-top: 34px;
    gap: 2px;
    padding-bottom: 10px;
  }
  
  .calendar-main .event-link,
  .weekly-calendar .event-link {
    font-size: 0.7em;
    border-radius: 3px;
    padding: 7px 5px;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }

  .calendar-main .more-events-link,
  .weekly-calendar .more-events-link {
    font-size: 0.7em !important;
  }

  .multiple-shows-icon {
    position: absolute;
    right: 4px;
    font-size: 10px;
    font-weight: 600;
    opacity: 0.7;
    z-index: 1000;
  }

}

@media (min-width: 768px) {
  .calendar-main .multiple-shows-icon {
    background: #fff;
    border-radius: 3px;
    padding: 2px 3px;
    position: absolute;
    right: 9px !important;
    font-size: 10px;
    font-weight: 600;
    opacity: 0.7;
  }
  


  .weekly-calendar .multiple-shows-icon {
    background: #fff;
    border-radius: 3px;
    padding: 2px 3px;
    position: absolute;
    right: 4px;
    font-size: 10px;
    font-weight: 600;
    opacity: 0.7;
  }

  }

.calendar-main .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.calendar-main .intro,
.calendar-dayview-page .intro {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.calendar-main .intro p,
.calendar-dayview-page .intro p {
  margin-bottom: 0px;
}

.calendar-main .intro a,
.calendar-dayview-page .intro a {
  color: #7958D2;
}

.calendar-main .intro a:hover,
.calendar-dayview-page .intro a:hover {
  text-decoration: underline;
}

.calendar-main .more-events-link,
.weekly-calendar .more-events-link {
  display: block;
  font-size: 13px;
  color: #666;
  text-decoration: none;
  padding: 2px 4px;
  margin-top: 2px;
}

.calendar-main .more-events-link:hover,
.weekly-calendar .more-events-link:hover {
  color: #333;
  text-decoration: underline;
}

.zpiategorzedu-logo {
  width: 45px;
  height: 45px;
  border-radius:100px;
}

.show-page .show-dates-section {
  margin-bottom: 2rem;
}

.show-page .show-dates {
  margin-top: 1rem;
}

.show-page .month-dates {
  margin-bottom: 1.5rem;
}

.show-page .month-name {
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 0.8rem;
  opacity: 0.9;
}

.show-page .dates-list {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.show-page .date-entry {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 0.3rem;
  background-color: var(--background-color);
  border-radius: 10px;
  background-color: white;
  border: 0.5px solid #EAECF0;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding-bottom: 10px;
}

.show-page .date-day {
  font-weight: 600;
  font-size: 18px;
  margin-top: 5px;
  min-width: 1.5rem;
}

.show-page .date-weekday {
  color: var(--secondary-text-color);
  text-transform: capitalize;
  min-width: 6rem;
  text-align: center;
  font-weight: 600;
  background-color: #F9F5FF;
  align-self: center;
  color: #6941C6;
  border-radius: 4px;
  padding: 5px;
  font-size: 12px;
}

.show-page .date-location {
  color: var(--Gray-500, #667085);
  font-size: 12px;
}

.stats-rank .priv-rank-container .rank-number {
  font-weight: 600;
  text-align: center;
  flex-shrink: 0;
  width: 40px; /* Zapewnia minimalną szerokość dla numeru rankingu */
  background-color: #F9F5FF;
  height: 75px;
  align-self: center;
  color: #6941C6;
  border-radius: 4px;
  padding: 10px;
  display: flex; /* Dodaj flexbox */
  align-items: center; /* Wyrównaj zawartość pionowo */
  justify-content: center; /* Wyrównaj zawartość poziomo */
}

.show-page .date-time {
  color: var(--text-color);
  font-weight: 500;
}

@media (max-width: 768px) {
  
  .show-page .date-entry {
    flex: 1 1 31%;
  }

  .show-page .date-weekday {
    width: 100%;
  }

  .calendar-main .update-info {
    font-size: 13px;
  }

  .desktop {
    display: none;
  }

  .calendar-main .calendar-container-header {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    gap: 24px;
  }

  .calendar-main .filter-container {
    width: 100%;
  }

  
}

@media (min-width: 768px) {
  .mobile {
    display: none;
  }
}

/* Calendar Day View */
.calendar-day-view {
  padding: 20px 0;
}

.day-view-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.day-view-buttons .filter-container {
  width: auto;
  white-space: nowrap;
  flex: 0 0 auto;
  justify-content: end;
}

.day-view-buttons .quick-button {
  white-space: nowrap;
  flex: 0 0 auto;
}


.calendar-day-view .current-date {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}


.calendar-day-view .events-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.calendar-day-view .event-card {
  display: flex;
  align-items: center;
  margin-bottom: 0px; /* Dopasowanie marginesu dolnego */
  border: 0.5px solid #EAECF0;
  background: #fff;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  min-height: 111px;
}



.calendar-day-view .event-card:hover {
  background: #F9FAFB;
}

.calendar-day-view .show-poster {
  width: 60px !important;
  height: 90px !important;
  object-fit: cover;
  border-radius: 5px !important;;
  margin-right: 20px !important;;
}

.calendar-day-view .show-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
}

.calendar-day-view .show-location {
  color: #475467;
  font-size: 14px;
}

.calendar-day-view .show-t-r {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.calendar-day-view .show-title {
  text-decoration: none;
  color: inherit;
}

.calendar-day-view .show-title h3 {
  font-size: 16px;
  font-weight: 600;
}

.calendar-day-view .show-rating {
  display: flex;
  align-items: center;
  gap: 0px;
  margin-top: 2px;
  font-size: 15px;
}

.calendar-day-view .show-rating span {
  color: #000 !important;
  opacity: 0.6;
}

.calendar-day-view .rating-star {
  width: 16px;
  height: 16px;
}

.calendar-day-view .show-theater {
  color: #475467;
  font-size: 13px;
}

.calendar-day-view .day-navigation {
  margin-top: 36px;
}

@media (max-width:768px) {
  .day-view-buttons {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 16px;
  }
  
  .day-view-buttons .filter-container {
    width: auto;
    white-space: nowrap;
    flex: 0 0 auto;
    justify-content: start;
  }
  
  .day-view-buttons .quick-button {
    white-space: nowrap;
    flex: 1 1 auto;
    width: 100%;
  }

  .calendar-day-view .current-date {
    font-size: 16px;
  }

  .weekly-calendar .day-name {
    font-size: 0.7em !important;
  }

}

.weekly-calendar {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 1dvb 0;
}


@media (max-width: 900px) {
  .weekly-calendar {
    gap: 4px;
  }
}

.weekly-calendar .calendar-day {
  min-height: 261px;
  min-width: 0; /* Ważne dla prawidłowego działania text-overflow */
  border: 0.5px solid #EAECF0;
  border-radius: 10px;
  padding: 8px;
  transition: all 0.2s ease;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  flex-direction: column;
  flex: 1;
}

.weekly-l-c {
  min-height: 346px;
}

@media (max-width: 768px) {
  .weekly-l-c {
    min-height: 630px;
  }

  .weekly-calendar .calendar-day {
    min-height: 268px;
}
}

.spinner-container {
  padding-top: 70px;
}

.weekly-calendar .has-events:hover {
  box-shadow: inset 0 3px 0px #E9D7FE;
}

.weekly-calendar .day-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
  margin-top: 8px;
}

.weekly-calendar .day-name {
  font-size: 0.8em;
  color: #666;
  text-transform: capitalize;
}

.weekly-calendar .day-number {
  font-size: 1.2em;
  font-weight: bold;
}

.weekly-calendar .day-events {
  display:flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
  position: relative;
}

.weekly-calendar .has-events {
  background-color: #ffff;
  cursor: pointer;

}

@media (max-width: 768px) {
  .weekly-calendar {
    flex-wrap: wrap;
  }

  .weekly-calendar .calendar-day {
    flex: 1 1 calc(25% - 6px); /* 4 kolumny na mobile */
    min-width: calc(25% - 6px);
    border-radius: 10px;
    padding: 4px;
  }
}

@media (max-width: 900px) {
  .weekly-calendar {
    gap: 4px;
  }

}

.no-events {
  text-align: center;
  margin-top: 20px;
}

.no-events-container {
  height: 125px;
  padding-top: 16px;
}


.reset-city-filter {
  opacity: 0.6;
  font-style: italic;
}

.show-more-item {
  opacity: 0.6;
}

.show-tooltip {
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.show-tooltip.show {
  opacity: 1;
}

/* Dodaj style dla fallbacku pozycjonowania */
.show-tooltip[data-popper-placement="left"] {
  margin-right: 3px;
}

.show-tooltip[data-popper-placement="right"] {
  margin-left: 3px;
}

.show-tooltip[data-popper-placement="top"] {
  margin-bottom: 4px;
}

.show-tooltip[data-popper-placement="bottom"] {
  margin-top: 4px;
}

.show-tooltip .tooltip-inner {
  max-width: 300px;
  min-width: 300px;
  padding: 12px;
  background: white;
  color: black;
  border: 1px solid #ddd;
  box-shadow: 0 4px 30px rgba(0,0,0,0.1);
  pointer-events: auto !important;
}

.show-tooltip .tooltip-inner:hover {
  background: #F9FAFB;
}

.show-tooltip-content {
  display: flex;
  gap: 14px;
  justify-content: left;
  align-items: center;
}

.tooltip-poster-container {
  width: 70px;
  flex-shrink: 0;
}

.tooltip-poster {
  position: relative;
  width: 100%;
  overflow: hidden; /* Zapobiega wychodzeniu obrazu poza kontener */
}

.tooltip-poster::before {
  content: "";
  display: block;
  padding-top: 150%; /* Stosunek proporcji 3:2 */
}

.tooltip-poster img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Zachowuje proporcje obrazu */
  border-radius: 4px; /* Opcjonalnie, jeśli chcesz zaokrąglone rogi */
}

.tooltip-info {
  flex-direction: column;
  align-items: left;
  justify-content: left;
  text-align: left;
  gap: 5px;
  display: flex;
  flex: 1 1 auto;
}

.tooltip-info h4 {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  margin-top: 4px;
}

.tooltip-location {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}

.tooltip-theater {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}

.tooltip-rating {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 3px;
}

.tooltip-rating .rating-star {
  width: 16px;
  height: 16px;
  margin-top:-2px;
}

.tooltip-chevrons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-chevrons .chevron-right {
  filter: invert(25%) sepia(49%) saturate(2513%) hue-rotate(240deg) brightness(101%) contrast(94%);
}

.show-tooltip .tooltip-inner:hover .chevron-right {
  filter: invert(31%) sepia(51%) saturate(5525%) hue-rotate(247deg) brightness(81%) contrast(89%);
}

/* Zmiana koloru strzałki tooltipa */
.show-tooltip .tooltip-arrow::before {
  border-right-color: #ddd !important; /* dla strzałki z prawej */
}

.show-tooltip .tooltip-arrow::after {
  border-right-color: white !important; /* dla strzałki z prawej */
  margin-left: 1px; /* delikatne przesunięcie dla efektu obramowania */
}

/* Dla tooltipa wyświetlanego z lewej strony */
.show-tooltip[data-popper-placement="left"] .tooltip-arrow::before {
  border-left-color: #ddd !important;
}

.show-tooltip[data-popper-placement="left"] .tooltip-arrow::after {
  border-left-color: white !important;
  margin-right: 1px;
}

/* Dodaj na końcu pliku */
@media (max-width: 767px) {


  /* Poprawka dla strzałki tooltipa na górze */
  .show-tooltip[data-popper-placement^="top"] .tooltip-arrow::before {
    border-top-color: #fff !important;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
  }

  .show-tooltip[data-popper-placement^="top"] .tooltip-arrow::after {
    border-top-color: white !important;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
  }
}

/* Style dla plakatu w sekcji best-played */
.best-played .show-poster {
  width: 120px;
  height: 180px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  flex-shrink: 0; /* Zapobiega ściskaniu plakatu */
}

.best-played .poster-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.best-played .poster-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center; /* Centrowanie w pionie i poziomie */
}

/* Poprawka dla plakatu na stronie głównej */
.homepage .best-played .show-poster {
  width: 120px;
  height: 180px;
}

.homepage .best-played .poster-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

/* Poprawka dla plakatu w BestAllTime i BestPlayedPage */
.best-played-col .show-poster {
  width: 120px;
  height: 180px;
}

.best-played-col .poster-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

/* Style dla plakatu w BestAllTime i BestPlayedPage */
.best-played-col .show-card .show-poster {
  width: 120px;
  height: 180px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  flex-shrink: 0;
}

.best-played-col .show-card .poster-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.best-played-col .show-card .poster-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

/* Dodatkowe style dla skeleton loadera */
.best-played-col .show-card .skeleton-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  z-index: 1;
}

.best-played-col .show-card .poster-img.visible {
  opacity: 1;
  z-index: 2;
}

.best-played-col .show-card .poster-img.hidden {
  opacity: 0;
  z-index: 1;
}

.multiple-shows-tag {
  color: #3538CD;
  background-color: #EEF4FF;
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 12px;
  margin-top: 4px;
  display: inline-block;
  max-width:fit-content;
}




/* Sposób 2: Używając kombinacji selektorów */
@media all and (min-width: 768px) and (-webkit-min-device-pixel-ratio:0)  {
  @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
    .calendar-main .event-link:hover .multiple-shows-icon {
      margin-right: -5px;
    }
  }
}

.welcome-modal .modal-content,
.welcome-modal .modal-dialog,
.onboarding-modal .modal-dialog,
.onboarding-modal .modal-content {
  max-width: 1000px;
  padding: 0px;
  padding-right: 36px;

}

.welcome-modal .welcome-content,
.onboarding-modal .onboarding-content {
  text-align: left;
  padding: 0px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: space-between;
  padding-bottom: 16px;
  padding-top: 16px;
}

.onboarding-modal .onboarding-content {
  gap: 20px;
  height: 100%;
  justify-content: center;
  padding-top: 50px;
}

.onboarding-modal .modal-section {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
}

.welcome-modal .welcome-modal-image-container,
.onboarding-modal .onboarding-modal-image-container {
  width: 50%;
  background: #F4B934;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-left:10px;
  padding-right:15px;
}

.onboarding-modal .onboarding-modal-image-container {
  overflow: hidden;
  max-height: 420px;
    align-items: start;
    padding-top: 70px;
    background: linear-gradient(45deg, #7F56D9, #53389E);
}

.welcome-modal .modal-section,
.onboarding-modal .modal-section {
  width: 50%;
}



.welcome-modal .welcome-modal-container,
.onboarding-modal .onboarding-modal-container {
  display: flex;
  flex-direction: row;
  gap: 36px;
  overflow: hidden;
}


@media (max-width: 880px) {
  .welcome-modal .welcome-modal-container,
  .onboarding-modal .onboarding-modal-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .welcome-modal .modal-section,
  .onboarding-modal .modal-section {
    width: 100%;
  }

  .onboarding-modal .navigation-buttons {
    margin-top: 10px;
    gap: 8px;
  }

  .welcome-modal .welcome-modal-image-container,
  .onboarding-modal .onboarding-modal-image-container {
    width: 100%;
    max-height: 186px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 10px;
    overflow: hidden;
    align-items: start;
    justify-content: center;
    padding-left:0px;
    padding-right:5px;
  }

    .onboarding-modal .onboarding-modal-image-container {
      padding-right: 0px;
      max-height: 260px;
    }

  .welcome-modal .welcome-content {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    gap: 4px;
  }

  
  .onboarding-modal .onboarding-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    gap: 16px;
  }
  
  .onboarding-modal .modal-section {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom:0px;
    gap: 0px;
  }

  .welcome-modal .modal-content,
  .welcome-modal .modal-dialog,
  .onboarding-modal .modal-dialog,
  .onboarding-modal .modal-content {
  padding-right: 0px;
}
.welcome-modal .welcome-modal-image {
  margin-top: 10px;
  margin-bottom:0px;
}


.onboarding-modal .onboarding-modal-image {
  margin-top: -35px;
  margin-bottom:0px;
  max-width: 60% !important;
}

.welcome-modal h2 {
  font-size: 20px !important;
  margin-top: -6px !important;
}

.onboarding-modal h2 {
  font-size: 18px !important;
  margin-top: -6px !important;
}

}

.welcome-modal .welcome-modal-image {
  max-width: 100%;
  object-fit: cover;
  object-position: center center;
  margin-bottom: -2px;
}

.onboarding-modal .onboarding-modal-image {
  max-width: 70%;
  object-fit: cover;
  object-position: center center;
  margin-bottom: -2px;
}

@media (min-width: 900px) {
  .welcome-modal .welcome-modal-image,
  .onboarding-modal .onboarding-modal-image {
    animation: moveUp 5s forwards;
    animation-delay: 2s;
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-34px);
  }
}





@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.welcome-modal h2,
.onboarding-modal h2 {
  background: linear-gradient(45deg, #6941C6, #B692F6, #DC6803, #FDB022);
  background-size: 300% 300%;
  animation: gradient 10s ease infinite;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: bold;
  width: 100%;
  font-size: 24px;
  margin-top: 5px;
}

.welcome-modal .welcome-features,
.onboarding-modal .onboarding-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 18px;
  margin-top: 20px;
}

.welcome-modal .feature-title-container,
.onboarding-modal .feature-title-container {
  display: flex;
  flex-direction: row;
  gap: 8px;

}

.welcome-modal .feature-title-container img,
.onboarding-modal .feature-title-container img {
  width: 14px;
  height: 14px;
  margin-top: 2px;
  filter: invert(66%) sepia(88%) saturate(6438%) hue-rotate(240deg) brightness(91%) contrast(86%);
}

.welcome-modal .feature,
.onboarding-modal .feature {
  transition: transform 0.2s;
}


.welcome-modal .feature h3,
.onboarding-modal .feature h3 {
  font-size: 15px;
  margin-bottom: 6px;
  color: #333;
}

.welcome-modal .feature p,
.onboarding-modal .feature p {
  color: #667085;
  font-size: 13px;
  line-height: 1.5;
  margin: 0;
}

.welcome-modal .welcome-modal-button,
.onboarding-modal .onboarding-modal-button {
  cursor: pointer !important;
  width: 100%;
  margin-top: 18px;
}

@media (max-width: 768px) {
  .welcome-modal .welcome-features,
  .onboarding-modal .onboarding-features {
    grid-template-columns: 1fr;
  }
}

  .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 15px;
  }

  .final-step-buttons {
    margin-top: 20px;
  }

.no-blur-modal {
  padding-right: 0 !important; /* Dodane */
}

.no-blur-modal .modal-dialog {
  margin-right: 0 !important; /* Dodane */
}

.modal-dialog-bottom {
  padding-right: 0 !important; /* Dodane */
}

/* Dodaj nową klasę */
.cookie-consent-open {
  padding-right: 0 !important;
  overflow-y: auto !important;
}

html.cookie-consent-open {
  overflow-y: auto !important;
  margin-right: 0 !important;
}

.explore-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  gap: 12px;
  padding: 0px;
}

.explore-block {
  padding: 30px;
  border-radius: 15px;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}

.explore-block:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  color: white;
}

.explore-block h2 {
  font-size: 20px;
  margin-bottom: 8px;
  font-weight: bold;
  color: white;
}

.explore-block p {
  font-size: 14px;
  opacity: 0.9;
  margin: 0;
}

.explore-block .block-description {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.explore-icon {
  width: 30px;
  height: 30px;
  filter: invert(99%) sepia(2%) saturate(427%) hue-rotate(196deg) brightness(1000%) contrast(100%);
  margin-top: 2px;
}

@media (max-width: 520px) {
  .explore-block {
    padding: 22px;
    min-height: 134px;
  }

  .explore-grid {
    gap: 9px;
  }

  .explore-block h2 {
    font-size: 19px;
    margin-bottom: 3px;
  }

  .explore-block p {
    font-size: 13px;
  }

  .explore-icon {
    width: 20px;
    height: 20px;
    margin-top: 0px;
  }
}

/* Style dla sekcji popularnych w Explore */


.explore .shows-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 10px;
}

.explore .show-card {
  flex: 0 0 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  min-width: 95px;
  position: relative;
  margin: 0px 8px 8px 8px;
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.explore .show-card:hover {
  transform: translateY(-2px);
}

.explore .show-card:hover .show-poster {
  transform: translateY(-2px);
}

.explore .show-poster {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
}

.explore .show-card .show-poster::before {
  content: "";
  display: block;
  padding-top: 150%;
}

.explore .show-card .show-poster img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius:8px;
}


@media (min-width: 528px) {
  .explore .show-card {
    flex: 0 0 calc(11.4% - 20px); /* podstawowa szerokość */
    max-width: calc(11.4% - 20px); /* maksymalna szerokość */
  }
}

.title-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: fit-content;
  align-items: center;
}

.title-col .update-info {
  width: 100%;
  margin: 0;
  padding-top: 10px;
  color: white;
  opacity: 0.5
} 

@media (min-width: 768px) {
  .title-col {
    justify-content: center;
  }

  .title-row {
    padding-bottom: 38px !important;
    padding-top: 30px !important;
  }

  .title-row .page-title-container {
    gap: 14px !important;
  }

  .title-row .page-title {
   font-size: 28px !important;
  }
  .title-icon {
    width: 24px !important;
    height: 24px !important;
  }
}

.title-row {
  background: #101828;
  padding-top: 0px;
  padding-bottom: 30px;
  padding-top: 20px;
  border-bottom: 0px #EAECF0 solid;
}

.title-row .page-title {
 color: white;

font-size: 22px;
margin-bottom: 0px !important;
margin-top: 0px !important;

}

.title-row .page-title-container {


width: fit-content;
padding-left: 10px;
padding-right: 10px;
display: flex;
flex-direction: row;
gap: 10px;
align-items: center;


 /* border-left: 5px solid #42307D;
 padding-left: 16px; */
}

.title-icon {
  width: 21px;
  height: 21px;
  filter: invert(99%) sepia(2%) saturate(427%) hue-rotate(196deg) brightness(1000%) contrast(100%);


}

.admin-panel .arrow,
.moderator-panel .arrow {
  width: 24px;
  padding: 0;
  margin: 0;
  padding-left: 4px;
  margin-right: 4px;
  text-decoration: none;
  text-align: left;
  color: #6444BD;
  margin-top: 3px;
}

.admin-panel h6,
.moderator-panel h6 {
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 10px;
  background: #F9F5FF;
  padding: 8px;
}

.privacypolicy {
  text-align: justify;
}

.privacy-section {
  margin-bottom: 30px;
  line-height: 1.6;
}

.privacy-section h2 {
  margin-bottom: 14px;
}

.privacy-section ul {
  margin-top: -4px;
}

.privacy-section li {
  opacity: 0.7;
  font-size: 14px;
  margin-bottom: 4px;
}

.global-plays {
  color: #015CCA;
}

.global-musicals {
  color: #6444BD;
}

.small-spinner {
  margin: 0;
  padding: 0;
  width: 11px;
  height: 11px;
}

.small-spinner-desktop {
  margin: 0;
  padding: 0;
  width: 14px;
  height: 14px;
  --bs-spinner-border-width: 0.16em;
}

.theater-summary {
  background: #0E182A;
  padding-bottom: 50px;
}

.theater-summary .posters-container {
  width: 100%;
  margin: 0 auto;
  display: grid;
  justify-content: center;
  grid-auto-flow: row dense;
}

.theater-summary .skeleton-loader {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.theater-summary .poster-img {
  transition: opacity 0.3s ease;
}

.theater-summary .poster-img.hidden {
  opacity: 0;
}

.theater-summary .poster-img.visible {
  opacity: 1;
}

.theater-summary .form-control {
  font-size: 10px !important;
  padding: 5px;
}

.trending-section {
  height: 311px;
}

@media (max-width: 786px) {
  .trending-section {
    height: 238px;
  }
}

@media (min-width: 769px) {

.trending-section .resp-pre-xl {
  overflow: hidden;
}

}

.trending-section .slick-slider {
  margin-left: 0px;
}

.trending-section .featured-show-card {
  width: 260px;
  height: 260px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  margin: 0;
  margin-right: 10px;
  background: #1D2939;
  border: 0 solid;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.trending-section .show-image-layer {
  width: 100%;
  height: 100%;
  position: relative;
}

.trending-section .show-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.35s ease;
}

.trending-section .featured-show-card:hover .show-image {
  transform: scale(1.07);
}

.trending-section .show-info-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0.85) 40%, rgba(0,0,0,0.6) 62%, rgba(0,0,0,0));
  padding: 20px;
}

.trending-section .show-info {
  color: white;
  text-align: left;
}

.trending-section .trending-title {
  font-size: 1.05rem;
  font-weight: 600;
  margin-bottom: 5px;
  color: white;
}

.trending-section .trending-theater {
  font-size: 0.7rem;
  margin-bottom: 2px;
  opacity: 0.9;
}

.trending-section .trending-location {
  opacity: 0.8;
}

.trending-section .trending-category {
  font-size: 0.8rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
  margin-left: -2px;
}

@media (max-width: 768px) {

  .trending-section .featured-show-card {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    margin: 0;
    margin-right: 8px;
  }

  .trending-section .show-info-layer {
    padding: 16px;
  }

  .trending-section .trending-title  {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 5px;
    color: white;
  }
  
  .trending-section .trending-theater {
    font-size: 0.65rem;
    margin-bottom: 2px;
    opacity: 0.9;
  }
  
  .trending-section .trending-location {
    display: none;
  }
  
}