.featured-shows .show-card-wrapper .skeleton-card {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  
    .show-image-layer,
    .top-layer {
      img {
        max-width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }

  }
  
  .featured-shows .show-card-wrapper .skeleton-card.is-loading {
    .show-image-layer,
    .top-layer,
    p {
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
    }
  
    .show-image-layer {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  
    p {
      height: 54px;
    }
  }


  .featured-shows-desktop .show-card-wrapper .skeleton-card {
    background: #101828;
    border-radius: 10px;
  
    .show-image-layer,
    .top-layer {
      img {
        max-width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

  }
  
  .featured-shows-desktop .show-card-wrapper .skeleton-card.is-loading {
    .show-image-layer,
    .top-layer,
    p {
      background: #101828;
      background: linear-gradient(110deg, #344054 8%, #667085 18%, #344054 33%);
      border-radius: 10px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
    }

  
    .show-image-layer {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .show-info-layer {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

  
    p {
      height: 126px;
      margin:0;
      padding:0;
    }
  }

  
  
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
  
  .shows-grid .skeleton-card {

    border-radius: 5px;
  
    .show-poster {
      img {
        max-width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }

  }
  
  .shows-grid .skeleton-card.is-loading {
    .show-poster,
    p {
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
    }
  
    p {
      height: 20px;
      margin-bottom: 5px;
    }
  }

  .best-played-col .skeleton-card {
  
    .show-poster {
      img {
        max-width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

    
    };



  }
  
  .best-played-col .skeleton-card.is-loading {
    .show-poster,
    .show-info-basic,
    p {
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
    }
  
    p {
      height: 20px;
      width: 100%;
      margin: 0;
    }

    .show-info-basic {
        height: 60px;
        width: 100%;
    }

    

  }

/* Style dla tooltip-poster */
.tooltip-poster {
  position: relative;
  width: 100%;
  height: 110px;
  border-radius: 5px;
  overflow: hidden;
}

.tooltip-poster .skeleton-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  opacity: 0;
  transition: opacity 0.2s;
}

.tooltip-poster .skeleton-loader.visible {
  opacity: 1;
}

.tooltip-poster img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.2s;
}

.tooltip-poster img.visible {
  opacity: 1;
}

.tooltip-poster img.hidden {
  opacity: 0;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

/* Style dla best-played poster container */
.best-played .show-poster {
  position: relative;
  height: 200px;
  width: 133px;
}

.best-played .poster-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.best-played .skeleton-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 1;
}

.best-played .skeleton-loader.visible {
  opacity: 1;
}

.best-played .poster-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 2;
}

.best-played .poster-img.visible {
  opacity: 1;
}

.best-played .poster-img.hidden {
  opacity: 0;
}

.best-played .show-link {
  display: block;
  width: 100%;
  height: 100%;
}

/* Aktualizacja stylów dla my-shows skeleton loadera */
.my-shows .show-card .show-poster {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 10px;
}

.my-shows .show-card .poster-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.my-shows .show-card .skeleton-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 10px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  z-index: 1;
}

.my-shows .show-card .poster-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 2;
}

.my-shows .show-card .poster-img.visible {
  opacity: 1;
}

.my-shows .show-card .poster-img.hidden {
  opacity: 0;
}

.activity-card.skeleton-card {
  background: #fff;
  border: .5px solid #eaecf0;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 #1018280d;
  margin-bottom: 10px;
  padding: 10px;
}

.activity-card.skeleton-card .activity-content {
  height: auto !important;
}

.activity-card.skeleton-card .activity-show,
.activity-card.skeleton-card .activity-content,
.activity-card.skeleton-card .activity-poster  {
  margin: 0;
}

.skeleton-following-container::after {
  content: '';
  position: absolute;
  bottom: 0px; /* Ta sama wartość co padding-bottom */
  left: 0;
  right: 0;
  height: 180px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    #F8F9FC 50%,
    #F8F9FC 100%
  );
  pointer-events: none; /* Pozwala na klikanie elementów pod gradientem */
}

.activity-card.skeleton-card.is-loading {
  .activity-user {
    display: flex;
    align-items: center;
    margin-bottom: 11px;

    .user-avatar {
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      border-radius: 50%;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
    }

    p {
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      border-radius: 4px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
    }
  }

  .activity-show {
    display: flex;
    gap: 0px;
    margin-bottom: 0px;

    .activity-poster {
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      border-radius: 4px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
    }

    .activity-show-info {
      flex: 1;
      
      p {
        background: #eee;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        border-radius: 4px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
        margin-bottom: 6px;
        margin-top: 6px;
      }
    }
  }
}

.trending-show-card.skeleton-card {
  background: #eee;
  border-radius: 12px;
  overflow: hidden;
  height: 260px;
  width: 260px;
  margin-right: 10px;
}


.trending-show-card.skeleton-card.is-loading {
  .show-image-layer {
    height: 260px;
    width: 260px;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
}

@media (max-width: 786px) {
.trending-show-card.skeleton-card {
  border-radius: 10px;
  height: 200px;
  width: 200px;
  margin-right: 8px;
}


.trending-show-card.skeleton-card.is-loading {
  .show-image-layer {
    height: 200px;
    width: 200px;
  }
}
}